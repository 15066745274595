import React, { FocusEventHandler, useCallback } from "react"

import NumberFormat, { NumberFormatProps, NumberFormatValues } from "react-number-format"

import { decimalToPercent, extractNumber, percentToDecimal } from "@ncs/ts-utils"

import { cssMixins } from "../../util"
import { FieldContainer, FieldContainerProps } from "./FieldContainer"

export interface NumericInputProps extends FieldContainerProps {
	/** receives a numeric parameter representing the input amount and the event object */
	onChange?: (newValue?: number) => void
	/** receives an object with formattedValue, value, and floatValue properties, along with the event object. */
	onValueChange?: NumberFormatProps["onValueChange"]
	/** The floatValue property is what is returned in onChange, which should be used most of the time */
	value?: number | string | null
	onFocus?: FocusEventHandler<HTMLInputElement>
	onBlur?: FocusEventHandler<HTMLInputElement>
	placeholder?: string
	min?: number
	max?: number
	/** Use a decimal in value state and onChange, but let the user interact with the percent in the
	 * field. For example, pass 0.0225 as value prop, but user sees and edits 2.25. */
	doPercentageMath?: boolean
	disabled?: boolean
	autoFocus?: boolean
	thousandSeparator?: NumberFormatProps["thousandSeparator"]
	displayType?: NumberFormatProps["displayType"]
	isNumericString?: NumberFormatProps["isNumericString"]
	allowNegative?: NumberFormatProps["allowNegative"]
	decimalScale?: NumberFormatProps["decimalScale"]
	fixedDecimalScale?: NumberFormatProps["fixedDecimalScale"]
}

export const NumericInput: React.FC<NumericInputProps> = React.memo(
	({
		onValueChange,
		onChange, // swallow this prop
		value,
		onFocus,
		onBlur,
		min,
		max,
		doPercentageMath,
		disabled,
		autoFocus = false,
		thousandSeparator = true,
		displayType = "input",
		isNumericString = false,
		allowNegative = false,
		decimalScale,
		fixedDecimalScale,
		label,
		error,
		fillContainer,
		placeholder,
		...rest
	}) => {
		function handleValueChange(values: NumberFormatValues) {
			const { floatValue } = values

			// Swallow meaningless updates, otherwise bugs are caused with codependent number fields updating each other.
			if (floatValue === value) {
				return
			}

			// onChange from NumberFormatProps takes the full event, but our implementation
			// will just take the value.
			if (typeof onChange === "function") {
				onChange(
					doPercentageMath ? percentToDecimal(floatValue, decimalScale) : floatValue
				)
			}
		}

		function handleIsAllowed(values: NumberFormatValues) {
			const { floatValue } = values

			// If float value is undefined then user just cleared the input field. Allow this.
			if (floatValue === undefined) {
				return true
			}

			// Otherwise, do our normal checks against the min / max.
			return (
				(typeof min === "undefined" || floatValue >= min) &&
				(typeof max === "undefined" || floatValue <= max)
			)
		}

		const getInputValue = useCallback(
			(inputValue: string | number | null | undefined) => {
				if (inputValue == null) {
					return ""
				} else {
					return doPercentageMath ?
							decimalToPercent(extractNumber(inputValue), decimalScale)
						:	inputValue
				}
			},
			[doPercentageMath, decimalScale]
		)

		return (
			<FieldContainer label={label} error={error} fillContainer={fillContainer} {...rest}>
				<NumberFormat
					isNumericString={value ? typeof value === "string" : isNumericString}
					thousandSeparator={thousandSeparator}
					displayType={displayType}
					allowNegative={allowNegative}
					decimalScale={decimalScale}
					fixedDecimalScale={fixedDecimalScale}
					onValueChange={onValueChange || handleValueChange}
					value={getInputValue(value)}
					onFocus={onFocus}
					onBlur={onBlur}
					isAllowed={handleIsAllowed}
					placeholder={placeholder ?? (label ? `${label}...` : undefined)}
					disabled={disabled}
					css={cssMixins.nonMuiFormElementStyle}
					autoFocus={autoFocus}
				/>
			</FieldContainer>
		)
	}
)
