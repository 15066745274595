import { FC } from "react"

import { Box, GridContainerProps } from "@ncs/web-legos"

import { Logo, LogoNames, LogoProps } from "./Logo"

export interface LogoCloudProps extends GridContainerProps {
	logoWidth?: LogoProps["width"]
}

const brands: LogoNames[] = [
	"ncs",
	"macneil",
	"csi",
	"oem",
	"ryko",
	"vacutech",
	"tss",
	"collegeOfClean",
	"services",
]

export const LogoCloud: FC<LogoCloudProps> = ({ logoWidth = 12, ...rest }) => {
	return (
		<Box
			display="flex"
			columnGap={5}
			rowGap={2}
			flexWrap="wrap"
			justifyContent="center"
			alignItems="center"
			mx="auto"
			{...rest}
		>
			{brands.map((brand) => (
				<Logo key={brand} company={brand} width={logoWidth} />
			))}
		</Box>
	)
}
