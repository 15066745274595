import { FC, Fragment, useMemo, useState } from "react"

import { css, Theme } from "@emotion/react"
import { Link, useHistory } from "react-router-dom"

import {
	CustomerPart,
	isNonParentCustomerPart,
	NonParentCustomerPart,
	useCustomerParts,
} from "@ncs/ncs-api"
import {
	AnimatedEntrance,
	Box,
	Button,
	Divider,
	Heading,
	Paragraph,
	PartImage,
	Price,
	useChangeCallback,
	useScreenSizeMatch,
} from "@ncs/web-legos"

import { useOrderTotals, useShopContext } from "~/contexts"
import {
	OrderSummaryRow,
	PageContentWrapper,
	QuantityControl,
	RemoveFromCartModal,
} from "~/shared-components"

import { EmptyCartModal } from "./components"

export const CartSummary: FC = () => {
	const history = useHistory()
	const screenIsSmall = useScreenSizeMatch("sm")
	const [partBeingRemoved, setPartBeingRemoved] = useState<CustomerPart | null>(null)
	const [showEmptyCardModal, setShowEmptyCardModal] = useState(false)
	const [{ cart }, shopDispatch] = useShopContext()
	const { totalItemCount, cartPartsSubtotal, taxesTotal, shippingTotal } = useOrderTotals()

	// Feed the cart parts into the parts endpoint so we're getting back the very latest
	// price data, just in case we're coming back to this page after some time has gone
	// by and data could have gone stale.
	const { data: customerParts } = useCustomerParts({
		params: {
			onlinePartId: cart.map(({ part }) => part.id),
		},
		queryConfig: {
			enabled: cart.length > 0,
		},
		pageSize: 999, // In case there are lots of items in the cart.
	})

	// The online parts endpoint has the side effect of turning our NonParentCustomerParts into CustomerParts.
	// Flip 'em back.
	const partsData = useMemo(() => {
		const parts: NonParentCustomerPart[] = []

		customerParts.forEach((onlinePart) => {
			if (!isNonParentCustomerPart(onlinePart)) {
				throw new Error(
					"There was a parent part in the cart. This should not have happened"
				)
			} else {
				parts.push(onlinePart)
			}
		})

		return parts
	}, [customerParts])

	const changeQuantity = (quantity: number, partId: string) => {
		shopDispatch({
			type: "update part cart quantity",
			payload: {
				partId,
				quantity,
			},
		})
	}

	// When we get back updated parts data from the server, put that into the cart.
	useChangeCallback(partsData, (updatedPartsData) => {
		shopDispatch({
			type: "update cart parts part data",
			payload: {
				parts: updatedPartsData,
			},
		})
	})

	return (
		<PageContentWrapper
			title="Cart"
			breadcrumbs={[{ name: "Shop", to: "/shop" }, { name: "Cart" }]}
		>
			<div css={outerContainerStyle}>
				<div>
					<Heading bold>
						Your cart: {totalItemCount} item{totalItemCount !== 1 ? "s" : ""}
					</Heading>
					<Divider />
					{totalItemCount === 0 && (
						<Paragraph color="secondary">
							There aren't any items in your cart yet.
						</Paragraph>
					)}
					{totalItemCount > 0 && !screenIsSmall && (
						<table css={tableStyle}>
							<tbody>
								{cart.map(({ part, quantity }) => (
									<tr key={part.id}>
										<td className="product-image">
											<Link to={`/shop/parts/${part.id}`}>
												<PartImage src={part.imageUrl} alt={part.title} />
											</Link>
										</td>
										<td className="product-name">
											<Link
												to={`/shop/parts/${part.id}`}
												css={underlineHoverStyle}
											>
												<Heading>{part.title}</Heading>
											</Link>
										</td>
										<td className="quantity">
											<QuantityControl
												value={quantity}
												onChange={(newCount) =>
													changeQuantity(newCount, part.id)
												}
												onChooseZero={() => setPartBeingRemoved(part)}
												useUpdateButton
											/>
										</td>
										<td className="price">
											<Price
												price={part.netPrice}
												nonDiscountedPrice={part.price}
												quantity={quantity}
											/>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					)}
					{screenIsSmall && (
						<>
							{cart.map(({ part, quantity }) => (
								<Fragment key={part.id}>
									<Box textAlign="center" my={0.5}>
										<Link to={`/shop/parts/${part.id}`}>
											<PartImage
												src={part.imageUrl}
												alt={part.title}
												maxWidth="12rem"
											/>
										</Link>
									</Box>
									<Link to={`/shop/parts/${part.id}`} css={underlineHoverStyle}>
										<Heading>{part.title}</Heading>
									</Link>
									<Box display="flex" alignItems="center" my={2}>
										<QuantityControl
											value={quantity}
											onChange={(newCount) =>
												changeQuantity(newCount, part.id)
											}
											onChooseZero={() => setPartBeingRemoved(part)}
											useUpdateButton
										/>
									</Box>
									<Price
										price={part.netPrice}
										nonDiscountedPrice={part.price}
										quantity={quantity}
									/>
									<Divider />
								</Fragment>
							))}
						</>
					)}
					{cart.length > 0 && (
						<Box mt={3} textAlign="right">
							<Button
								icon="times"
								iconFamily="light"
								onClick={() => setShowEmptyCardModal(true)}
							>
								Empty your cart
							</Button>
						</Box>
					)}
				</div>

				<div css={summaryStyle}>
					<AnimatedEntrance show>
						<div className="sticky-container">
							<Heading textAlign="center" variant="h1" mb={2}>
								Summary
							</Heading>
							<OrderSummaryRow
								label={`Subtotal (${totalItemCount} item${
									totalItemCount !== 1 ? "s" : ""
								})`}
								amount={cartPartsSubtotal}
							/>
							<OrderSummaryRow label="Taxes (est.)" amount={taxesTotal} />
							<OrderSummaryRow
								label={
									<>
										Freight
										<br />
										(calc. in checkout)
									</>
								}
								amount={shippingTotal || "—"}
							/>
							<Divider />
							<OrderSummaryRow
								label="Est. total"
								amount={cartPartsSubtotal + shippingTotal + taxesTotal}
								mb={1.5}
							/>
							<Button
								variant="primary-cta"
								fillContainer
								onClick={() => history.push("/shop/checkout")}
								disabled={totalItemCount < 1}
							>
								Proceed to Checkout
							</Button>
						</div>
					</AnimatedEntrance>
				</div>
			</div>

			<EmptyCartModal
				isOpen={showEmptyCardModal}
				onClose={() => setShowEmptyCardModal(false)}
			/>
			<RemoveFromCartModal
				isOpen={!!partBeingRemoved}
				onClose={() => setPartBeingRemoved(null)}
				partId={partBeingRemoved?.id ?? null}
				partTitle={partBeingRemoved?.title ?? null}
			/>
		</PageContentWrapper>
	)
}

const outerContainerStyle = (theme: Theme) => css`
	position: relative;
	display: grid;
	grid-template-columns: 1fr auto;
	${theme.breakpoints.down("sm")} {
		display: flex;
		flex-direction: column-reverse;
	}
`
const tableStyle = (theme: Theme) => css`
	td {
		border-bottom: 1px solid #eee;
		padding: 0.5rem;
		${theme.breakpoints.down("md")} {
			padding: 1rem 0.35rem;
		}
		&.quantity {
			min-width: 8rem;
		}
		&.product-image {
			max-width: 10rem;
			height: 8rem;
		}
	}
	tr:last-of-type td {
		border-bottom: 0;
	}
`
const summaryStyle = (theme: Theme) => css`
	position: relative;
	width: 22rem;
	border-left: 1px solid #eee;
	margin-left: 2rem;
	padding-left: 2rem;
	${theme.breakpoints.down("sm")} {
		padding: 0;
		margin: 0;
		border: 0;
		width: auto;
		margin-bottom: 5rem;
	}
	.sticky-container {
		position: sticky;
		top: 1rem;
		${theme.breakpoints.down("sm")} {
			position: relative;
			top: 0;
		}
	}
`
const underlineHoverStyle = css`
	text-decoration: none;
	&:hover {
		text-decoration: underline;
	}
`
