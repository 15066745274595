import { useMemo } from "react"

import uniqBy from "lodash/uniqBy"
import { useQueryClient } from "react-query"

import { unpythonify } from "@ncs/ts-utils"

import {
	ApiDeleteMutation,
	ApiGetQuery,
	ApiGetQueryOptions,
	ApiInfiniteGetQuery,
	ApiInfiniteGetQueryOptions,
	ApiPatchMutation,
	ApiPostMutation,
	DataExport,
	useDataExport,
	useDeleteRequest,
	useGetRequest,
	useInfiniteGetRequest,
	usePatchRequest,
	usePostRequest,
} from "../../request-hooks"
import { useEnumPropertyValidation } from "../../util"
import { LoginResponse } from "../auth"
import { LineItemType } from "../common"
import { SecurityUrlPaths } from "../security"
import {
	AdditionalUser,
	AdditionalUserDetails,
	BillToCustomers,
	BillToCustomersQueryParams,
	CreateAccountPost,
	CreateAdditionalUserPost,
	CreateCustomerContactPost,
	CreateCustomerContactsPost,
	CreateEquipmentManufacturerPost,
	CreateEquipmentModelPost,
	CreateEquipmentPost,
	CreateEquipmentVintagePost,
	CreateSiteSurveyPost,
	Customer,
	CustomerAccessorial,
	CustomerAlternateAddress,
	CustomerAssignmentBulkPost,
	CustomerBillTo,
	CustomerBrandCard,
	CustomerCartSite,
	CustomerContact,
	CustomerContacts,
	CustomerDetail,
	CustomerEquipment,
	CustomerEquipmentDispatch,
	CustomerEquipmentQueryParams,
	CustomerMetaDataBulkPost,
	CustomerMin,
	CustomerMinQueryParams,
	CustomerOnlineChemical,
	CustomerQueryParams,
	CustomerSite,
	CustomersUrlPaths,
	CustomerUserContactPost,
	CustomerUserContactRecord,
	CustomerUserContactRecordsQueryParam,
	Equipment,
	EquipmentManufacturer,
	EquipmentManufacturersQueryParams,
	EquipmentModel,
	EquipmentModelsQueryParams,
	EquipmentType,
	EquipmentVintage,
	EquipmentVintageCustomer,
	EquipmentVintagePart,
	EquipmentVintageQueryParams,
	EquipmentVintageWorkOrder,
	FreightClassDescription,
	FreightClassId,
	InvoiceHistoryItem,
	NewCustomerAlternateAddressPost,
	PostCustomerAccessorialUpdate,
	QuickOrder,
	QuickOrderDetails,
	QuickOrderPartDelete,
	QuickOrderPartPost,
	QuickOrderPatch,
	QuickOrderPost,
	QuickOrderSiteDelete,
	QuickOrderSitePost,
	SurveyResponse,
	UpdateAdditionalUserPatch,
	UpdateCustomerPatch,
	UpdateEquipmentContractPatch,
	UpdateEquipmentManufacturerPatch,
	UpdateEquipmentModelPatch,
	UpdateEquipmentPatch,
	UpdateEquipmentVintagePatch,
} from "./types"

export const useCustomer = (
	customerId: string | null | undefined,
	options?: ApiGetQueryOptions<CustomerDetail>
): ApiGetQuery<CustomerDetail> => {
	const [data, ...rest] = useGetRequest([CustomersUrlPaths.Customers, customerId ?? ""], {
		...options,
		queryConfig: {
			enabled: !!customerId,
			...options?.queryConfig,
		},
	})

	useEnumPropertyValidation(data, "freightClassId", FreightClassId)

	return [data, ...rest]
}

export const defaultCustomerQueryParams: CustomerQueryParams = {
	search: null,
	fields: null,
	service: true,
	accountActive: true,
	isActive: true,
	isServiceCustomer: null,
	isWarehouse: null,
	showKbmManaged: true,
	showNotKbmManaged: null,
	excludeTerritory: null,
	pricingMatrixId: null,
	territory: null,
	enterprise: null,
	region: null,
	organization: null,
}

export const useCustomers = (
	options?: ApiInfiniteGetQueryOptions<Customer, CustomerQueryParams>
): ApiInfiniteGetQuery<Customer> => {
	const request = useInfiniteGetRequest<Customer>([CustomersUrlPaths.Customers], {
		...options,
		params: {
			...defaultCustomerQueryParams,
			...options?.params,
		},
	})

	useEnumPropertyValidation(request.data, "freightClassId", FreightClassId)
	useEnumPropertyValidation(request.data, "freightClass", FreightClassDescription)

	return request
}

export const useUpdateCustomer = (): ApiPatchMutation<UpdateCustomerPatch> => {
	return usePatchRequest([CustomersUrlPaths.Customers], {
		keyToInvalidate: CustomersUrlPaths.Customers,
	})
}

export const useSite = (siteId: string | null): [CustomerSite | undefined, boolean] => {
	const [sites, loading] = useGetRequest<CustomerSite[]>(
		[CustomersUrlPaths.Customers, CustomersUrlPaths.CustomerSites],
		{
			queryConfig: {
				enabled: !!siteId,
			},
		}
	)

	const site = useMemo(() => {
		return (sites ?? []).find((s) => s.id === siteId)
	}, [sites, siteId])

	return [site, loading]
}

/**
 * Get the sites that the logged in user has access to.
 */
export const useSites = (): ApiGetQuery<CustomerSite[]> => {
	return useGetRequest<CustomerSite[]>(
		[CustomersUrlPaths.Customers, CustomersUrlPaths.CustomerSites],
		{
			mapper: (data) =>
				unpythonify(data).sort((a, b) => ((a?.name ?? "") > (b?.name ?? "") ? 1 : -1)),
		}
	)
}

export const useCustomerEquipmentSingle = (
	equipmentId?: string | null,
	options?: ApiGetQueryOptions<CustomerEquipment>
): ApiGetQuery<CustomerEquipment> => {
	return useGetRequest(
		[CustomersUrlPaths.Customers, CustomersUrlPaths.BayEquipment, equipmentId ?? ""],
		{
			...options,
			queryConfig: {
				enabled: !!equipmentId,
				...options?.queryConfig,
			},
		}
	)
}

export const initialCustomerEquipmentQueryParams: CustomerEquipmentQueryParams = {
	search: null,
	ordering: null,
}

/**
 * Returns the equipment of the logged in user.
 */
export const useCustomerEquipment = (
	options?: ApiInfiniteGetQueryOptions<CustomerEquipment, CustomerEquipmentQueryParams>
): ApiInfiniteGetQuery<CustomerEquipment> => {
	return useInfiniteGetRequest<CustomerEquipment>(
		[CustomersUrlPaths.Customers, CustomersUrlPaths.BayEquipment],
		options
	)
}

export const useEquipmentDispatches = (
	equipmentId: string | null
): ApiGetQuery<CustomerEquipmentDispatch[]> => {
	return useGetRequest(
		[
			CustomersUrlPaths.Customers,
			CustomersUrlPaths.BayEquipment,
			equipmentId ?? "",
			CustomersUrlPaths.Dispatches,
		],
		{
			queryConfig: {
				enabled: !!equipmentId,
			},
		}
	)
}

export const useCustomerQuickOrder = (
	customerId: string | null | undefined
): ApiGetQuery<CustomerOnlineChemical[]> => {
	return useGetRequest([CustomersUrlPaths.Customers, CustomersUrlPaths.OnlineChemicals], {
		params: {
			customer: customerId,
		},
		queryConfig: {
			enabled: !!customerId,

			// This call returns an error if the passed customer doesn't happen to have any quick
			// order parts. No need to keep retrying.
			retry: false,
		},
	})
}

export const useCustomerQuickOrderSites = (): ApiGetQuery<CustomerCartSite[]> => {
	return useGetRequest<CustomerCartSite[]>(
		[CustomersUrlPaths.Customers, CustomersUrlPaths.CartSites],
		{
			mapper: (data) =>
				unpythonify(data).sort((a, b) => ((a?.name ?? "") > (b?.name ?? "") ? 1 : -1)),
		}
	)
}

export const useEquipmentTypes = (): ApiGetQuery<EquipmentType[]> => {
	return useGetRequest([CustomersUrlPaths.Equipment, CustomersUrlPaths.Types])
}

export const useCustomerBillTos = (
	id: string | null | undefined
): ApiGetQuery<CustomerBillTo[]> => {
	const [results, ...rest] = useGetRequest<CustomerBillTo[]>(
		[CustomersUrlPaths.Customers, id ?? "", CustomersUrlPaths.BillTos],
		{
			queryConfig: {
				enabled: !!id,
			},
		}
	)

	// I've gotten duplicate results before, so apply a dedupe function here.
	return [results ? uniqBy(results, "id") : undefined, ...rest]
}

export const useCustomerAlternateAddresses = (
	customerId: string | null
): ApiGetQuery<CustomerAlternateAddress[]> => {
	return useGetRequest(
		[CustomersUrlPaths.Customers, customerId ?? "", CustomersUrlPaths.AlternateAddresses],
		{
			queryConfig: {
				enabled: !!customerId,
			},
			params: {
				isActive: true,
			},
		}
	)
}

export const useCreateCustomerAlternateAddress = (
	customerId: string
): ApiPostMutation<NewCustomerAlternateAddressPost, CustomerAlternateAddress> => {
	return usePostRequest(
		[CustomersUrlPaths.Customers, customerId, CustomersUrlPaths.AlternateAddresses],
		{
			keyToInvalidate: CustomersUrlPaths.Customers,
		}
	)
}

export const useUpdateCustomerAlternateAddress = (
	customerId: string
): ApiPatchMutation<Partial<CustomerAlternateAddress>, CustomerAlternateAddress> => {
	return usePatchRequest(
		[CustomersUrlPaths.Customers, customerId, CustomersUrlPaths.AlternateAddresses],
		{
			keyToInvalidate: CustomersUrlPaths.Customers,
		}
	)
}

export const useDeleteCustomerAlternateAddress = (customerId: string): ApiDeleteMutation => {
	return useDeleteRequest(
		[CustomersUrlPaths.Customers, customerId, CustomersUrlPaths.AlternateAddresses],
		{
			keyToInvalidate: CustomersUrlPaths.Customers,
		}
	)
}

export const useCreateCustomerAccount = (): ApiPostMutation<CreateAccountPost, LoginResponse> => {
	return usePostRequest([CustomersUrlPaths.Customers, CustomersUrlPaths.NewUserRequest])
}

export const useCustomerContactHistory = (
	options?: ApiGetQueryOptions<CustomerUserContactRecord[], CustomerUserContactRecordsQueryParam>
): ApiGetQuery<CustomerUserContactRecord[]> => {
	return useGetRequest(
		[CustomersUrlPaths.Customers, CustomersUrlPaths.CustomerContactRequest],
		options
	)
}

export const useSendCustomerContact = (): ApiPostMutation<CustomerUserContactPost> => {
	return usePostRequest(
		[CustomersUrlPaths.Customers, CustomersUrlPaths.CustomerContactRequest],
		{
			keyToInvalidate: [CustomersUrlPaths.Customers],
		}
	)
}

export const useQuickOrders = (): ApiGetQuery<QuickOrder[]> => {
	return useGetRequest([CustomersUrlPaths.Customers, CustomersUrlPaths.QuickOrder])
}

export const useCreateQuickOrder = (): ApiPostMutation<
	QuickOrderPost,
	{ quickOrderId: number }
> => {
	return usePostRequest([CustomersUrlPaths.Customers, CustomersUrlPaths.QuickOrder], {
		keyToInvalidate: CustomersUrlPaths.Customers,
	})
}

export const useUpdateQuickOrder = (): ApiPatchMutation<QuickOrderPatch> => {
	return usePatchRequest([CustomersUrlPaths.Customers, CustomersUrlPaths.QuickOrder], {
		keyToInvalidate: CustomersUrlPaths.Customers,
	})
}

export const useQuickOrderDetails = (id: number): ApiGetQuery<QuickOrderDetails> => {
	return useGetRequest(
		[
			CustomersUrlPaths.Customers,
			CustomersUrlPaths.Details,
			CustomersUrlPaths.QuickOrder,
			id.toString() ?? "",
		],
		{
			queryConfig: {
				enabled: !!id,
			},
		}
	)
}

export const useAddQuickOrderPart = (): ApiPostMutation<QuickOrderPartPost> => {
	return usePostRequest(
		[CustomersUrlPaths.Customers, CustomersUrlPaths.Part, CustomersUrlPaths.QuickOrder],
		{
			keyToInvalidate: CustomersUrlPaths.Customers,
		}
	)
}

export const useRemoveQuickOrderPart = (): ApiDeleteMutation<QuickOrderPartDelete> => {
	return useDeleteRequest(
		[CustomersUrlPaths.Customers, CustomersUrlPaths.Part, CustomersUrlPaths.QuickOrder],
		{
			keyToInvalidate: CustomersUrlPaths.Customers,
		}
	)
}

export const useAddQuickOrderSite = (): ApiPostMutation<QuickOrderSitePost> => {
	return usePostRequest(
		[CustomersUrlPaths.Customers, CustomersUrlPaths.Site, CustomersUrlPaths.QuickOrder],
		{
			keyToInvalidate: CustomersUrlPaths.Customers,
		}
	)
}

export const useRemoveQuickOrderSite = (): ApiDeleteMutation<QuickOrderSiteDelete> => {
	return useDeleteRequest(
		[CustomersUrlPaths.Customers, CustomersUrlPaths.Site, CustomersUrlPaths.QuickOrder],
		{
			keyToInvalidate: CustomersUrlPaths.Customers,
		}
	)
}

export const useCustomerBrandCards = (): ApiGetQuery<CustomerBrandCard[]> => {
	return useGetRequest([CustomersUrlPaths.Customers, CustomersUrlPaths.BrandCards])
}

export const useCustomerMins = (
	options?: ApiInfiniteGetQueryOptions<CustomerMin, CustomerMinQueryParams>
): ApiInfiniteGetQuery<CustomerMin> => {
	return useInfiniteGetRequest([CustomersUrlPaths.Customers, CustomersUrlPaths.Minimal], options)
}

export const defaultCustomerMinQueryParams: CustomerMinQueryParams = {
	search: null,
	region: null,
	territory: null,
	enterprise: null,
	organization: null,
	isFstActive: null, // Doesn't do anything?
	service: null,
	customer: null,
}

export const useExportCustomerMins = (params?: Partial<CustomerMinQueryParams>): DataExport => {
	return useDataExport([CustomersUrlPaths.Customers, CustomersUrlPaths.Minimal], params)
}

export const useEquipment = (customerId: string | null | undefined): ApiGetQuery<Equipment[]> => {
	return useGetRequest(
		[CustomersUrlPaths.Customers, customerId ?? "", CustomersUrlPaths.Equipment],
		{
			queryConfig: {
				enabled: !!customerId,
			},
		}
	)
}

export const useEquipmentSingular = (
	customerId: string | null | undefined,
	equipmentId: string | null | undefined
): ApiGetQuery<Equipment> => {
	return useGetRequest(
		[
			CustomersUrlPaths.Customers,
			customerId ?? "",
			CustomersUrlPaths.Equipment,
			equipmentId ?? "",
		],
		{
			queryConfig: {
				enabled: !!customerId && !!equipmentId,
			},
		}
	)
}

export const useCreateEquipment = (customerId: string): ApiPostMutation<CreateEquipmentPost> => {
	return usePostRequest([CustomersUrlPaths.Customers, customerId, CustomersUrlPaths.Equipment], {
		keyToInvalidate: CustomersUrlPaths.Customers,
	})
}

export const useUpdateEquipment = (customerId: string): ApiPatchMutation<UpdateEquipmentPatch> => {
	return usePatchRequest(
		[CustomersUrlPaths.Customers, customerId, CustomersUrlPaths.Equipment],
		{
			keyToInvalidate: CustomersUrlPaths.Customers,
		}
	)
}

export const useUpdateEquipmentContract = (
	customerId: string,
	equipmentId: string
): ApiPatchMutation<UpdateEquipmentContractPatch> => {
	return usePatchRequest(
		[
			CustomersUrlPaths.Customers,
			customerId,
			CustomersUrlPaths.Equipment,
			equipmentId,
			CustomersUrlPaths.Contracts,
		],
		{
			keyToInvalidate: CustomersUrlPaths.Customers,
		}
	)
}

export const useSiteSurveyResponses = (customerId: string): ApiGetQuery<SurveyResponse[]> => {
	return useGetRequest([CustomersUrlPaths.Customers, customerId, CustomersUrlPaths.SiteSurveys])
}

export const useCreateSiteSurvey = (customerId: string): ApiPostMutation<CreateSiteSurveyPost> => {
	return usePostRequest(
		[CustomersUrlPaths.Customers, customerId, CustomersUrlPaths.SiteSurveys],
		{
			keyToInvalidate: CustomersUrlPaths.Customers,
		}
	)
}

export const useCreateSiteSurveysForChildren = (customerId: string): ApiPostMutation<void> => {
	return usePostRequest(
		[
			CustomersUrlPaths.Customers,
			customerId,
			CustomersUrlPaths.SiteSurveys,
			CustomersUrlPaths.CreateForChildren,
		],
		{
			keyToInvalidate: CustomersUrlPaths.Customers,
		}
	)
}

export const useCreateSiteSurveysForSiblings = (customerId: string): ApiPostMutation<void> => {
	return usePostRequest(
		[
			CustomersUrlPaths.Customers,
			customerId,
			CustomersUrlPaths.SiteSurveys,
			CustomersUrlPaths.CreateForSiblings,
		],
		{
			keyToInvalidate: CustomersUrlPaths.Customers,
		}
	)
}

export const useCustomerContacts = (
	customerId: string | null | undefined
): ApiGetQuery<CustomerContacts> => {
	return useGetRequest([CustomersUrlPaths.Customers, CustomersUrlPaths.Contacts], {
		params: {
			customer: customerId,
		},
		queryConfig: {
			enabled: !!customerId,
		},
	})
}

export const getCustomerContacts = (customerId: string): ApiGetQuery<CustomerContact[]> => {
	return useGetRequest([CustomersUrlPaths.Customers, customerId, CustomersUrlPaths.Contacts], {})
}

export const useCreateCustomerContacts = (): ApiPostMutation<CreateCustomerContactsPost> => {
	return usePostRequest([CustomersUrlPaths.Customers, CustomersUrlPaths.Contacts], {
		keyToInvalidate: CustomersUrlPaths.Customers,
	})
}

export const useCreateCustomerContact = (
	customerId: string
): ApiPostMutation<CreateCustomerContactPost> => {
	return usePostRequest([CustomersUrlPaths.Customers, customerId, CustomersUrlPaths.Contacts], {
		keyToInvalidate: CustomersUrlPaths.Customers,
	})
}

export const useEditCustomerContact = (
	customerId: string,
	contactId: string
): ApiPatchMutation<CreateCustomerContactPost> => {
	return usePatchRequest(
		[CustomersUrlPaths.Customers, customerId, CustomersUrlPaths.Contacts, contactId],
		{
			keyToInvalidate: CustomersUrlPaths.Customers,
		}
	)
}

export const useDeleteCustomerContact = (customerId: string): ApiDeleteMutation => {
	return useDeleteRequest(
		[CustomersUrlPaths.Customers, customerId, CustomersUrlPaths.Contacts],
		{
			keyToInvalidate: CustomersUrlPaths.Customers,
		}
	)
}

/**
 * Get a list of additional users for a requesting superuser.
 */
export const useCustomerAdditionalUsers = (
	options?: ApiGetQueryOptions<AdditionalUser[]>
): ApiGetQuery<AdditionalUser[]> => {
	return useGetRequest(
		[CustomersUrlPaths.Customers, CustomersUrlPaths.Superuser, CustomersUrlPaths.Users],
		options
	)
}

export const useAdditionalUserDetails = (
	userId: string | null | undefined,
	options?: ApiGetQueryOptions<AdditionalUserDetails>
): ApiGetQuery<AdditionalUserDetails> => {
	return useGetRequest(
		[
			CustomersUrlPaths.Customers,
			CustomersUrlPaths.Superuser,
			CustomersUrlPaths.Users,
			userId ?? "",
		],
		{
			...options,
			queryConfig: {
				enabled: !!userId,
				...options?.queryConfig,
			},
		}
	)
}

export const useCreateAdditionalUser = (): ApiPostMutation<CreateAdditionalUserPost> => {
	return usePostRequest(
		[CustomersUrlPaths.Customers, CustomersUrlPaths.Superuser, CustomersUrlPaths.Users],
		{
			keyToInvalidate: [CustomersUrlPaths.Customers, SecurityUrlPaths.Security],
		}
	)
}

export const useUpdateAdditionalUser = (): ApiPatchMutation<UpdateAdditionalUserPatch> => {
	return usePatchRequest(
		[CustomersUrlPaths.Customers, CustomersUrlPaths.Superuser, CustomersUrlPaths.Users],
		{
			keyToInvalidate: [CustomersUrlPaths.Customers, SecurityUrlPaths.Security],
		}
	)
}

/**
 * Returns a list of all the accessorials and gives the price for this customer, and whether
 * or not it's set as a default.
 */
export const useCustomerAccessorials = (
	customerId: string | null | undefined
): ApiGetQuery<CustomerAccessorial[]> => {
	const [data, ...rest] = useGetRequest<CustomerAccessorial[]>(
		[CustomersUrlPaths.Customers, CustomersUrlPaths.AccessorialDefaults, customerId ?? ""],
		{
			queryConfig: {
				enabled: !!customerId,
			},
		}
	)

	useEnumPropertyValidation(data, "lineItemTypeId", LineItemType)

	return [data, ...rest]
}

export const useUpdateCustomerAccessorial = (): ApiPostMutation<PostCustomerAccessorialUpdate> => {
	const queryClient = useQueryClient()

	return usePostRequest([CustomersUrlPaths.Customers, CustomersUrlPaths.AccessorialDefaults], {
		keyToInvalidate: [[CustomersUrlPaths.Customers, CustomersUrlPaths.AccessorialDefaults]],
		onSuccess: (...[, requestData]) => {
			queryClient.setQueryData<CustomerAccessorial[] | undefined>(
				[
					CustomersUrlPaths.Customers,
					CustomersUrlPaths.AccessorialDefaults,
					requestData.customerId,
				],
				(cachedData) => {
					if (cachedData) {
						return cachedData.map((accessorial) => {
							if (accessorial.lineItemTypeId === requestData.lineItemTypeId) {
								return {
									...accessorial,
									default: requestData.default,
								}
							}

							return accessorial
						})
					}

					return cachedData
				}
			)
		},
	})
}

export const useCustomerInvoiceHistory = (
	customerId: string | null | undefined,
	options?: ApiInfiniteGetQueryOptions<InvoiceHistoryItem, void>
): ApiInfiniteGetQuery<InvoiceHistoryItem> => {
	return useInfiniteGetRequest(
		[CustomersUrlPaths.Customers, customerId ?? "", CustomersUrlPaths.Invoices],
		{
			...options,
			queryConfig: {
				enabled: !!customerId,
				...options?.queryConfig,
			},
		}
	)
}

/**
 * Get all the customers that bill to the passed in customer.
 */
export const useBillToCustomers = (
	billToId: string | null | undefined
): ApiGetQuery<BillToCustomers> => {
	return useGetRequest<BillToCustomers, BillToCustomersQueryParams>(
		[CustomersUrlPaths.Customers, CustomersUrlPaths.BillToCustomers],
		{
			params: {
				customer: billToId || null,
			},
			queryConfig: {
				enabled: !!billToId,
			},
		}
	)
}

export const useEquipmentVintages = (
	options?: ApiInfiniteGetQueryOptions<EquipmentVintage, EquipmentVintageQueryParams>
): ApiInfiniteGetQuery<EquipmentVintage> => {
	return useInfiniteGetRequest(
		[CustomersUrlPaths.Equipment, CustomersUrlPaths.Vintages],
		options
	)
}

export const useCreateEquipmentVintage = (): ApiPostMutation<CreateEquipmentVintagePost> => {
	return usePostRequest([CustomersUrlPaths.Equipment, CustomersUrlPaths.Vintages], {
		keyToInvalidate: CustomersUrlPaths.Equipment,
	})
}

export const useUpdateEquipmentVintage = (): ApiPatchMutation<UpdateEquipmentVintagePatch> => {
	return usePatchRequest([CustomersUrlPaths.Equipment, CustomersUrlPaths.Vintages], {
		keyToInvalidate: CustomersUrlPaths.Equipment,
	})
}

export const useEquipmentModels = (
	options?: ApiInfiniteGetQueryOptions<EquipmentModel, EquipmentModelsQueryParams>
): ApiInfiniteGetQuery<EquipmentModel> => {
	return useInfiniteGetRequest([CustomersUrlPaths.Equipment, CustomersUrlPaths.Models], options)
}

export const useEquipmentModel = (
	modelId: string | null | undefined
): ApiGetQuery<EquipmentModel> => {
	return useGetRequest([CustomersUrlPaths.Equipment, CustomersUrlPaths.Models, modelId ?? ""], {
		queryConfig: {
			enabled: !!modelId,
		},
	})
}

export const useCreateEquipmentModel = (): ApiPostMutation<CreateEquipmentModelPost> => {
	return usePostRequest([CustomersUrlPaths.Equipment, CustomersUrlPaths.Models], {
		keyToInvalidate: CustomersUrlPaths.Equipment,
	})
}

export const useUpdateEquipmentModel = (): ApiPatchMutation<UpdateEquipmentModelPatch> => {
	return usePatchRequest([CustomersUrlPaths.Equipment, CustomersUrlPaths.Models], {
		keyToInvalidate: CustomersUrlPaths.Equipment,
	})
}

export const useEquipmentManufacturers = (
	options?: ApiInfiniteGetQueryOptions<EquipmentManufacturer, EquipmentManufacturersQueryParams>
): ApiInfiniteGetQuery<EquipmentManufacturer> => {
	return useInfiniteGetRequest(
		[CustomersUrlPaths.Equipment, CustomersUrlPaths.Manufacturers],
		options
	)
}

export const useEquipmentManufacturer = (
	id: string | null | undefined,
	options?: ApiGetQueryOptions<EquipmentManufacturer>
): ApiGetQuery<EquipmentManufacturer> => {
	return useGetRequest(
		[CustomersUrlPaths.Equipment, CustomersUrlPaths.Manufacturers, id ?? ""],
		{
			...options,
			queryConfig: {
				enabled: !!id,
				...options?.queryConfig,
			},
		}
	)
}

export const useCreateEquipmentManufacturer =
	(): ApiPostMutation<CreateEquipmentManufacturerPost> => {
		return usePostRequest([CustomersUrlPaths.Equipment, CustomersUrlPaths.Manufacturers], {
			keyToInvalidate: CustomersUrlPaths.Equipment,
		})
	}

export const useUpdateEquipmentManufacturer =
	(): ApiPatchMutation<UpdateEquipmentManufacturerPatch> => {
		return usePatchRequest([CustomersUrlPaths.Equipment, CustomersUrlPaths.Manufacturers], {
			keyToInvalidate: CustomersUrlPaths.Equipment,
		})
	}

export const useEquipmentVintageCustomers = (
	vintageId: string | null | undefined
): ApiInfiniteGetQuery<EquipmentVintageCustomer> => {
	return useInfiniteGetRequest(
		[CustomersUrlPaths.Equipment, CustomersUrlPaths.CustomerEquipment],
		{
			params: {
				vintage: vintageId,
			},
			queryConfig: {
				enabled: !!vintageId,
			},
		}
	)
}

export const useEquipmentVintageParts = (
	vintageId: string | null | undefined,
	options?: ApiInfiniteGetQueryOptions<EquipmentVintagePart, void>
): ApiInfiniteGetQuery<EquipmentVintagePart> => {
	return useInfiniteGetRequest([CustomersUrlPaths.Equipment, CustomersUrlPaths.Parts], {
		...options,
		params: {
			vintage: vintageId,
		},
		queryConfig: {
			enabled: !!vintageId,
			...options?.queryConfig,
		},
	})
}

export const useEquipmentVintageWorkOrders = (
	vintageId: string | null | undefined,
	options?: ApiInfiniteGetQueryOptions<EquipmentVintageWorkOrder, void>
): ApiInfiniteGetQuery<EquipmentVintageWorkOrder> => {
	return useInfiniteGetRequest(
		[CustomersUrlPaths.Equipment, CustomersUrlPaths.WorkOrderMachine],
		{
			...options,
			params: {
				vintage: vintageId,
			},
			queryConfig: {
				enabled: !!vintageId,
				...options?.queryConfig,
			},
		}
	)
}

export const useCustomerMetaBulkPost = (): ApiPostMutation<CustomerMetaDataBulkPost> => {
	return usePostRequest([CustomersUrlPaths.Customers, CustomersUrlPaths.BulkCustomerMeta], {
		keyToInvalidate: [CustomersUrlPaths.Customers],
	})
}

export const useCustomerAssignmentBulkPost = (): ApiPostMutation<CustomerAssignmentBulkPost> => {
	return usePostRequest(
		[CustomersUrlPaths.Customers, CustomersUrlPaths.BulkCustomerAssignment],
		{
			keyToInvalidate: [CustomersUrlPaths.Customers],
		}
	)
}
