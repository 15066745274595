import React, { useMemo, useState } from "react"

import { css } from "@emotion/react"
import { Skeleton } from "@material-ui/lab"

import { partImageFallback } from "../../images"
import { Box, BoxProps } from "../layout"

export interface PartImageProps extends BoxProps {
	src?: string | null
	alt?: string | null
	isLoading?: boolean
	skeleton?: {
		size?: number
		containerProps?: BoxProps
	}
	showBorder?: boolean
	objectPosition?: "top" | "right" | "bottom" | "left" | string
}

export const PartImage: React.FC<PartImageProps> = ({
	src,
	alt,
	height,
	minHeight,
	maxHeight,
	width,
	minWidth = "3rem",
	maxWidth,
	objectPosition = "top center",
	display = "block",
	isLoading,
	skeleton = {},
	showBorder,
	...rest
}) => {
	const [errored, setErrored] = useState(false)

	const style = useMemo(() => {
		return css`
			border: ${showBorder ? "1px solid #eee" : undefined};
			img {
				display: inline;
				border-radius: 4px;
				object-fit: contain;
				object-position: ${objectPosition};
				height: 100%;
				width: 100%;
			}
		`
	}, [objectPosition, showBorder])

	return (
		<Box
			display={display}
			{...rest}
			css={style}
			height={height}
			minHeight={minHeight}
			maxHeight={maxHeight}
			width={width}
			minWidth={minWidth}
			maxWidth={maxWidth}
		>
			<div css={imageZoomContainerStyle}>
				{isLoading ?
					<Box
						display="flex"
						justifyContent="center"
						alignItems="center"
						{...skeleton.containerProps}
					>
						<Skeleton
							variant="circle"
							height={skeleton.size ?? 100}
							width={skeleton.size ?? 100}
						/>
					</Box>
				:	<img
						src={errored ? partImageFallback : src || partImageFallback}
						alt={alt ?? ""}
						onError={() => setErrored(true)}
					/>
				}
			</div>
		</Box>
	)
}

// Zoom in a little bit because most part images have tons of empty whitespace around them.
const imageZoomContainerStyle = css`
	overflow: hidden;
	line-height: 0;
	border-radius: 5px;
	height: inherit;
	min-height: inherit;
	max-height: inherit;
	width: inherit;
	min-width: inherit;
	max-width: inherit;
	img {
		transform: scale(1.1);
	}
`
