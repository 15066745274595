import dayjs, { Dayjs, OpUnitType, UnitType } from "dayjs"

import {
	ConnectedMachineWashCounts,
	CustomerWeather,
	FourDigitMachineCode,
	PortalReport,
	PortalReportName,
	ReportTimeSpan,
	useConnectedMachineWashCounts,
	useCustomerWeather,
	useFourDigitMachineCodes,
} from "@ncs/ncs-api"
import { DateFormat, getTimeAgoStartDate, TimeAgo } from "@ncs/ts-utils"

export const c12yAutoRefreshConfig = {
	staleTime: 1000 * 30,
	refetchInterval: 1000 * 30,
	refetchIntervalInBackground: false,
	refetchOnWindowFocus: true,
}

/**
 * Map `ReportTimeSpan` enum values to their equivalent DayJs unit for use in date time math.
 */
export const reportTimeSpanToDayJsUnit: Record<ReportTimeSpan, OpUnitType> = {
	[ReportTimeSpan.Hourly]: "h",
	[ReportTimeSpan.Daily]: "d",
	[ReportTimeSpan.Weekly]: "w",
	[ReportTimeSpan.Monthly]: "M",
}

/**
 * Given a time ago and a frequency, create an array of Dayjs objects that are at the very
 * beginning of the time period. The beginning of the array is farthest back in time and
 * goes up to now.
 */
export const getReportTimePeriods = (timeAgo: TimeAgo, frequency: ReportTimeSpan): Dayjs[] => {
	const unit = reportTimeSpanToDayJsUnit[frequency]

	// Start with today, going back to the appropriate start unit.
	let index = dayjs().startOf(unit)
	// Get the start date. Make it 11:59pm the night before.
	const startDate = getTimeAgoStartDate(timeAgo).subtract(1, "d").endOf("d")

	const periods: Dayjs[] = []

	while (index.isAfter(startDate)) {
		periods.push(index)
		if (unit === "w") {
			index = index.subtract(7, "d")
		} else {
			index = index.subtract(1, unit as UnitType) // "w" case handled above
		}
	}

	return periods.reverse()
}

export const makeDeviceEventsReport = (
	codes: FourDigitMachineCode[],
	timeAgo: TimeAgo,
	frequency: ReportTimeSpan
): PortalReport => {
	const periods = getReportTimePeriods(timeAgo, frequency)
	const periodCounts = new Map<Dayjs, number>()
	periods.forEach((period) => periodCounts.set(period, 0))

	codes.forEach((event) => {
		// Go through the periods and see which one it matches.
		for (let i = 0; i < periods.length; i += 1) {
			if (dayjs(event.eventOn).isSame(periods[i], reportTimeSpanToDayJsUnit[frequency])) {
				const current = periodCounts.get(periods[i]) || 0
				periodCounts.set(periods[i], current + 1)
				break
			}
		}
	})

	const data: {
		date: string
		events: number
	}[] = []

	periodCounts.forEach((value, key) => {
		data.push({
			date: key.toISOString(),
			events: value,
		})
	})

	return {
		xKey: "date",
		xLabel: "Time",
		yLabel: "Wash Counts",
		dataKeys: { events: "Events" },
		data,
	}
}

export const makeDeviceWashCountsReport = (
	washCounts: ConnectedMachineWashCounts[],
	timeAgo: TimeAgo,
	frequency: ReportTimeSpan
): PortalReport => {
	const periodCounts = new Map<Dayjs, ConnectedMachineWashCounts[]>()
	const periods = getReportTimePeriods(timeAgo, frequency)
	periods.forEach((period) => periodCounts.set(period, []))

	washCounts.forEach((count) => {
		for (let i = 0; i < periods.length; i += 1) {
			if (dayjs(count.eventDate).isSame(periods[i], reportTimeSpanToDayJsUnit[frequency])) {
				const current = periodCounts.get(periods[i])
				if (current) periodCounts.set(periods[i], [count, ...current])
				break
			}
		}
	})

	const data: {
		date: string
		washCount: number
	}[] = []

	periods.forEach((period, index) => {
		const counts = periodCounts.get(period)

		if (counts) {
			const first = counts[0]
			const last = counts[counts.length - 1]
			let total = (last?.total ?? 0) - (first?.total ?? 0)

			// If there's only counts heartbeat in the period, then try to find the period previous that has counts
			// and compare it to the last one there.
			if (counts.length === 1 && index > 0) {
				let backwardsIndex = index - 1
				let lastPeriodsCounts: ConnectedMachineWashCounts[] | null = null

				while (backwardsIndex >= 0 && lastPeriodsCounts == null) {
					const prevPeriod = periods[backwardsIndex]
					const prevPeriodCounts = prevPeriod ? periodCounts.get(prevPeriod) : null

					if (prevPeriodCounts?.length) {
						lastPeriodsCounts = prevPeriodCounts
					}

					backwardsIndex -= 1
				}

				if (lastPeriodsCounts) {
					total =
						(last?.total ?? 0) -
						(lastPeriodsCounts[lastPeriodsCounts.length - 1]?.total ?? 0)
				}
			}

			data.push({
				date: period.toISOString(),
				washCount: total,
			})
		}
	})

	return {
		xKey: "date",
		xLabel: "Time",
		yLabel: "Wash Counts",
		dataKeys: { washCount: "Washes" },
		data,
	}
}

export const makeCustomerWeatherReport = (
	weather: CustomerWeather[],
	timeAgo: TimeAgo,
	frequency: ReportTimeSpan
): PortalReport => {
	const periodCounts = new Map<Dayjs, CustomerWeather[]>()
	const periods = getReportTimePeriods(timeAgo, frequency)
	periods.forEach((period) => periodCounts.set(period, []))

	weather.forEach((w) => {
		for (let i = 0; i < periods.length; i += 1) {
			if (dayjs(w.timestamp).isSame(periods[i], reportTimeSpanToDayJsUnit[frequency])) {
				const current = periodCounts.get(periods[i])
				if (current) periodCounts.set(periods[i], [w, ...current])
				break
			}
		}
	})

	const data: {
		date: string
		high: number
		low: number
	}[] = []

	periodCounts.forEach((value, key) => {
		data.push({
			date: key.toISOString(),
			high: value.length ? Math.max(...value.map((v) => v.temperature)) : 0,
			low: value.length ? Math.min(...value.map((v) => v.temperature)) : 0,
		})
	})

	return {
		data,
		xKey: "date",
		xLabel: "Date",
		yLabel: "Temperature",
		dataKeys: { high: "High temperature", low: "Low temperature" },
	}
}

export const useCustomerConnectivityReports = (params: {
	customerId?: string | null
	deviceId?: string | null
	timeAgo: TimeAgo
	frequency: ReportTimeSpan
}): Partial<Record<PortalReportName, PortalReport>> & { isLoading: boolean } => {
	const { customerId, deviceId, timeAgo, frequency } = params

	const startDate = getTimeAgoStartDate(timeAgo).format(DateFormat.DateQueryParam)

	const {
		data: codes,
		isLoading: isLoadingCodes,
		isFetching: isFetchingCodes,
	} = useFourDigitMachineCodes(deviceId, {
		params: {
			startDate,
			endDate: null,
			pageSize: 99999,
		},
		manualPagination: true,
	})

	const {
		data: weather,
		isLoading: isLoadingWeather,
		isFetching: isFetchingWeather,
	} = useCustomerWeather(customerId, {
		params: {
			startDate,
			endDate: null,
			pageSize: 99999,
		},
		manualPagination: true,
	})

	const [counts, countsLoading] = useConnectedMachineWashCounts(deviceId, {
		params: {
			startDate,
			endDate: null,
		},
	})

	return {
		[PortalReportName.MachineEvents]: makeDeviceEventsReport(codes, timeAgo, frequency),
		[PortalReportName.WashCounts]: makeDeviceWashCountsReport(
			counts ?? [],
			timeAgo,
			frequency
		),
		[PortalReportName.Weather]: makeCustomerWeatherReport(weather ?? [], timeAgo, frequency),
		isLoading:
			isLoadingCodes ||
			isFetchingCodes ||
			isLoadingWeather ||
			isFetchingWeather ||
			countsLoading,
	}
}
