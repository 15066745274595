import React from "react"

import { Control, Controller, FieldValues, Path } from "react-hook-form"

import { titleCase } from "@ncs/ts-utils"

import { TextInput, TextInputProps } from "../inputs"

export interface TextInputFormFieldProps<TFieldValues extends FieldValues> extends TextInputProps {
	name: Path<TFieldValues>
	control: Control<TFieldValues>
	placeholder?: string
	emptyValueFallback?: string | null
}

export const TextInputFormField = <TFieldValues extends FieldValues>({
	name,
	control,
	placeholder,
	emptyValueFallback = null,
	...rest
}: TextInputFormFieldProps<TFieldValues>): React.ReactElement => {
	return (
		<Controller
			name={name}
			control={control}
			render={({ field: { onBlur, value, onChange }, fieldState: { error } }) => {
				return (
					<TextInput
						placeholder={placeholder}
						label={rest.label ?? titleCase(name) ?? undefined}
						{...rest}
						onBlur={onBlur}
						value={value != null ? String(value) : emptyValueFallback}
						// TextInput won't give us an empty string unless we explicitly tell it to,
						// which we're not. So if it gives us null then here we return whatever
						// the emptyValueFallback is.
						onChange={(newValue) =>
							onChange(
								newValue == null && emptyValueFallback !== undefined ?
									emptyValueFallback
								:	newValue
							)
						}
						error={error?.message}
					/>
				)
			}}
		/>
	)
}
