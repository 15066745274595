import { useEffect, useRef, useState } from "react"

import { AxiosPromise, AxiosResponse } from "axios"
import isEqual from "lodash/isEqual"
import qs from "query-string"

import { CamelToSnakeCaseNested, pythonify, unpythonify } from "@ncs/ts-utils"

import {
	ApiDelayedRequest,
	ApiDelayedRequestConfig,
	ApiDeleteMutation,
	ApiGetQuery,
	ApiGetQueryOptions,
	ApiInfiniteGetQuery,
	ApiInfiniteGetQueryOptions,
	ApiPatchMutation,
	ApiPatchMutationOptions,
	ApiPostMutation,
	ApiPostMutationOptions,
	DataExport,
	useDataExport,
	useDelayedRequest,
	useDeleteRequest,
	useGetRequest,
	useInfiniteGetRequest,
	usePatchRequest,
	usePostRequest,
} from "../../request-hooks"
import { apiClient, buildUrl, usePrevious, WorkOrderDocument } from "../../util"
import { LookupsUrlPaths } from "../lookups"
import { PartOrdersUrlPaths } from "../parts-order"
import { UserMinimal } from "../security"
import {
	AddCustomerRestrictedPartPost,
	AddOpenFulfillmentPartPost,
	AddRequestsToShipmentPostResponse,
	BillOfMaterial,
	BillOfMaterialQueryParams,
	BinLocation,
	BinLocationQueryParams,
	BinManagementBin,
	BinManagementBinPatch,
	BinManagementBinPost,
	BinManagementBinQueryParams,
	BinPart,
	BinPartQueryParams,
	CancelInventoryRequestPatch,
	ChemicalForecast,
	ChemicalForecastItem,
	ChemicalForecastItemsQueryParams,
	ChemicalForecastPatch,
	ChemicalForecastPost,
	ChemicalForecastQueryParams,
	CompleteBillOfMaterialPost,
	CompletePickListPost,
	CreateBillOfMaterialPost,
	CreateInventoryRequestLinePost,
	CreateInventoryRequestPost,
	CreateInventorySheetPost,
	CreateInventoryTransactionPost,
	CreateManufacturedPartPost,
	CustomerRestrictedPart,
	CustomerRestrictedPartDelete,
	CustomerRestrictedPartQueryParams,
	CycleCountPart,
	CycleSheetSubmissionPost,
	DefaultOrderBinsQueryParams,
	GenericLabelPost,
	InventoryAllocation,
	InventoryAllocationQueryParams,
	InventoryLocation,
	InventoryPart,
	InventoryPartQueryParams,
	InventoryRequest,
	InventoryRequestListItem,
	InventoryRequestQueryParams,
	InventoryTransaction,
	InventoryTransactionBins,
	InventoryTransactionBinsPost,
	InventoryTransactionInitialData,
	InventoryTransactionQueryParams,
	InventoryUrlPaths,
	LocalPart,
	LocalPartQueryParams,
	LocationLevel,
	LocationLevelQueryParams,
	LocationPartOrders,
	LocationPartSummary,
	LocationPartSummaryPatch,
	LocationPartSummaryPost,
	LocationPartSummaryQueryParams,
	LocationPickListAssignmentsQueryParams,
	LocationVendorSummariesParams,
	LocationVendorSummary,
	ManufacturedPart,
	OpenFulfillmentPart,
	OverstockedPart,
	OverstockedPartQueryParams,
	PartDefaultBin,
	PartGroup,
	PartGroupDiscount,
	PartGroupDiscountPatch,
	PartGroupDiscountPost,
	PartGroupDiscountQueryParams,
	PartGroupMember,
	PartGroupMemberPost,
	PartGroupMemberQueryParams,
	PartGroupPatch,
	PartGroupPost,
	PartInBin,
	PartLevel,
	PartLevelQueryParams,
	PartOrderWarehouseStock,
	PrintBinLabelsRequest,
	RdcPart,
	RdcPartBulkPost,
	RdcPartPost,
	RdcPartsQueryParams,
	RdcPartSummary,
	ReassignPickListPost,
	ReplacementSuccess,
	ReplacePartPost,
	ReplenishmentDetail,
	ReplenishmentDetailQueryParams,
	ReplenishmentIgnoredPart,
	ReplenishmentSubmissionPost,
	RequestCycleCountSheetPost,
	SetPickListInProgressPost,
	StaleAndOverstockedParts,
	StaleAndOverstockedPartsPost,
	UpdateBillOfMaterialPatch,
	UpdateInventoryPartImagePost,
	UpdateInventoryPartPatch,
	UpdateInventoryRequestLinePatch,
	UpdateManufacturedPartPatch,
	VendorContactInfoPatch,
	VendorManagement,
	VendorManagementPatch,
	VendorManagementsQueryParams,
	VendorPartRequestDetail,
} from "./types"

export const useInventoryPart = (
	partId: string | null | undefined,
	options?: ApiGetQueryOptions<InventoryPart>
): ApiGetQuery<InventoryPart> => {
	return useGetRequest<InventoryPart, InventoryPartQueryParams>(
		[InventoryUrlPaths.Inventory, InventoryUrlPaths.Parts, partId ?? ""],
		{
			...options,
			params: {
				...initialInventoryPartQueryParams,
				allParts: true,
				skipRestrictedCheck: true,
				...options?.params,
			},
			queryConfig: {
				enabled: !!partId,
				...options?.queryConfig,
			},
		}
	)
}

export const useInventoryParts = (
	options?: ApiInfiniteGetQueryOptions<InventoryPart, InventoryPartQueryParams>
): ApiInfiniteGetQuery<InventoryPart> => {
	return useInfiniteGetRequest([InventoryUrlPaths.Inventory, InventoryUrlPaths.Parts], {
		...options,
		params: {
			...initialInventoryPartQueryParams,
			...options?.params,
		},
	})
}

export const initialInventoryPartQueryParams: InventoryPartQueryParams = {
	search: null,
	ordering: null,
	partNumber: null,
	description: null,
	unitOfMeasure: null,
	allParts: null,
	service: null,
	restricted: null,
	restrictionsCustomer: null,
	skipRestrictedCheck: null,
}

export const useUpdateInventoryPart = (): ApiPatchMutation<
	UpdateInventoryPartPatch,
	InventoryPart
> => {
	return usePatchRequest([InventoryUrlPaths.Inventory, InventoryUrlPaths.Parts], {
		keyToInvalidate: InventoryUrlPaths.Inventory,
		queryParams: {
			allParts: true,
			skipRestrictedCheck: true,
		},
	})
}

export const useUpdateInventoryPartImage = (): ((
	data: UpdateInventoryPartImagePost
) => Promise<void>) => {
	const post = usePostRequest<FormData>(
		[InventoryUrlPaths.Inventory, InventoryUrlPaths.UpdatePartImage],
		{
			keyToInvalidate: InventoryUrlPaths.Inventory,
		}
	)

	const call = async (data: UpdateInventoryPartImagePost): Promise<void> => {
		const formData = new FormData()
		formData.append("part_id", data.partId)
		// Leave the file out to set the part's image URL to null.
		if (data.file) {
			formData.append("file", data.file)
		}

		await post(formData)
	}

	return call
}

export const defaultPartLevelQueryParams: PartLevelQueryParams = {
	search: null,
	ordering: null,
	location: null,
	territory: null,
}

export const useInventoryPartLevels = (
	partId: string | null | undefined,
	options?: ApiInfiniteGetQueryOptions<PartLevel, PartLevelQueryParams>
): ApiInfiniteGetQuery<PartLevel> => {
	return useInfiniteGetRequest(
		[
			InventoryUrlPaths.Inventory,
			InventoryUrlPaths.Parts,
			partId ?? "",
			InventoryUrlPaths.Levels,
		],
		{
			...options,
			queryConfig: {
				...options?.queryConfig,
				enabled: !!partId,
			},
		}
	)
}

export const useInventoryLocations = (): ApiGetQuery<InventoryLocation[]> => {
	return useGetRequest([InventoryUrlPaths.Inventory, InventoryUrlPaths.Locations])
}

export const useInventoryLocation = (
	locationId: string | null
): ApiGetQuery<InventoryLocation> => {
	return useGetRequest(
		[InventoryUrlPaths.Inventory, InventoryUrlPaths.Locations, locationId ?? ""],
		{
			queryConfig: {
				enabled: !!locationId,
			},
		}
	)
}

// This endpoint on the backend is a POST for some reason, even though it seems like GET makes more sense.
// The hook will try to handle that weirdness here and work as if it was using useGetRequest.
/** @deprecated */
export const useStaleAndOverstockedInventory = (
	params: StaleAndOverstockedPartsPost
): [StaleAndOverstockedParts | undefined, boolean] => {
	const [data, setData] = useState<StaleAndOverstockedParts>()
	const [isLoading, setIsLoading] = useState(false)
	const performedInitialFetch = useRef(false)

	const requestInventoryData = usePostRequest<
		StaleAndOverstockedPartsPost,
		StaleAndOverstockedParts
	>([InventoryUrlPaths.Inventory, InventoryUrlPaths.OverStocked])

	const prevParams = usePrevious(params)
	useEffect(() => {
		const fetchParts = async (): Promise<void> => {
			setIsLoading(true)
			performedInitialFetch.current = true
			try {
				// (Note that params does not have nullish values trimmed out.)
				const parts = await requestInventoryData(params)
				setData(unpythonify(parts.data))
			} catch (e) {
				console.error(e)
			} finally {
				setIsLoading(false)
			}
		}

		if (!isEqual(params, prevParams) || !performedInitialFetch.current) {
			void fetchParts()
		}
	}, [params, prevParams, requestInventoryData])

	return [data, isLoading]
}

/** @deprecated */
export const useReplenishmentIgnoredParts = (
	locationId: string | null
): ApiGetQuery<ReplenishmentIgnoredPart[]> => {
	return useGetRequest([InventoryUrlPaths.Inventory, InventoryUrlPaths.ReplenishmentIgnore], {
		params: {
			location: locationId,
		},
	})
}

export const useOpenFulfillmentParts = (
	locationId: string | null
): ApiGetQuery<OpenFulfillmentPart[]> => {
	return useGetRequest([InventoryUrlPaths.Inventory, InventoryUrlPaths.ReplenishmentOverstock], {
		params: {
			location: locationId,
		},
	})
}

export const useAddOpenFulfillmentPart = (): ApiPostMutation<AddOpenFulfillmentPartPost> => {
	return usePostRequest(
		[InventoryUrlPaths.Inventory, InventoryUrlPaths.ReplenishmentOverstock],
		{
			keyToInvalidate: [
				InventoryUrlPaths.Inventory,
				InventoryUrlPaths.ReplenishmentOverstock,
			],
		}
	)
}

export const useDeleteOpenFulfillmentPart = (): ApiDeleteMutation => {
	return useDeleteRequest(
		[InventoryUrlPaths.Inventory, InventoryUrlPaths.ReplenishmentOverstock],
		{
			keyToInvalidate: [
				InventoryUrlPaths.Inventory,
				InventoryUrlPaths.ReplenishmentOverstock,
			],
		}
	)
}

/**
 * This times out for Dallas!! Use the new `useDelayedLocationVendorSummaries`.
 * @deprecated
 */
export const useLocationVendorSummaries = (
	options?: ApiGetQueryOptions<LocationVendorSummary[], LocationVendorSummariesParams>
): ApiGetQuery<LocationVendorSummary[]> => {
	return useGetRequest(
		[InventoryUrlPaths.Inventory, InventoryUrlPaths.Replenishment, InventoryUrlPaths.Summary],
		{
			...options,
			params: options?.params,
		}
	)
}

export const useDelayedLocationVendorSummaries = ({
	params,
	config,
}: {
	params: LocationVendorSummariesParams
	config?: Partial<ApiDelayedRequestConfig>
}): ApiDelayedRequest<LocationVendorSummary[]> => {
	return useDelayedRequest(
		[InventoryUrlPaths.Inventory, InventoryUrlPaths.Replenishment, InventoryUrlPaths.Summary],
		params,
		config
	)
}

export const useReplenishmentDetail = (
	params: ReplenishmentDetailQueryParams
): ApiGetQuery<ReplenishmentDetail> => {
	return useGetRequest(
		[
			InventoryUrlPaths.Inventory,
			InventoryUrlPaths.Replenishment,
			InventoryUrlPaths.Detail,
			InventoryUrlPaths.V2,
		],
		{
			params,
		}
	)
}

export interface SubmitReplenishmentPartsOptions {
	invalidateCache: boolean
}

const defaultSubmitReplenishmentPartsOptions: SubmitReplenishmentPartsOptions = {
	invalidateCache: true,
}

export const useSubmitReplenishmentParts = (
	options?: Partial<SubmitReplenishmentPartsOptions>
): ApiPostMutation<ReplenishmentSubmissionPost, AddRequestsToShipmentPostResponse> => {
	const { invalidateCache } = {
		...defaultSubmitReplenishmentPartsOptions,
		...options,
	}

	return usePostRequest(
		[
			InventoryUrlPaths.Inventory,
			InventoryUrlPaths.Replenishment,
			InventoryUrlPaths.Submission,
			InventoryUrlPaths.V2,
		],
		{
			keyToInvalidate:
				invalidateCache ?
					[
						InventoryUrlPaths.Inventory,
						PartOrdersUrlPaths.PartOrders,
						PartOrdersUrlPaths.Parts,
					]
				:	undefined,
		}
	)
}

/**
 * Get the details of the part involved in the location vendor request.
 */
export const useVendorPartRequestDetail = ({
	partId,
	locationId,
}: {
	partId: string | null
	locationId: string | null
}): ApiGetQuery<VendorPartRequestDetail> => {
	return useGetRequest(
		[InventoryUrlPaths.Inventory, InventoryUrlPaths.Replenishment, InventoryUrlPaths.Part],
		{
			queryConfig: {
				enabled: !!partId && !!locationId,
			},
			params: {
				part: partId,
				location: locationId,
			},
		}
	)
}

export const useVendorManagements = (
	options?: ApiInfiniteGetQueryOptions<VendorManagement, VendorManagementsQueryParams>
): ApiInfiniteGetQuery<VendorManagement> => {
	return useInfiniteGetRequest([InventoryUrlPaths.Inventory, InventoryUrlPaths.Vendor], {
		...options,
	})
}

export const useVendor = (vendorId: string | null | undefined): ApiGetQuery<VendorManagement> => {
	return useGetRequest([InventoryUrlPaths.Inventory, InventoryUrlPaths.Vendor, vendorId ?? ""], {
		queryConfig: {
			enabled: !!vendorId,
		},
	})
}

export const useUpdateVendorManagement = (): ApiPatchMutation<VendorManagementPatch> => {
	return usePatchRequest<VendorManagementPatch>(
		[InventoryUrlPaths.Inventory, InventoryUrlPaths.Vendor],
		{
			keyToInvalidate: [
				InventoryUrlPaths.Inventory,
				InventoryUrlPaths.Vendor,
				LookupsUrlPaths.Vendors,
			],
		}
	)
}

export const useUpdateVendorContactInfo = (): ApiPatchMutation<VendorContactInfoPatch> => {
	return usePatchRequest([InventoryUrlPaths.Inventory, InventoryUrlPaths.VendorContactInfo], {
		keyToInvalidate: InventoryUrlPaths.Inventory,
	})
}

export const defaultRdcPartsQueryParams: RdcPartsQueryParams = {
	part: null,
	location: null,
	search: null,
	ordering: null,
}

export const useRdcParts = (
	options?: ApiInfiniteGetQueryOptions<RdcPart, RdcPartsQueryParams>
): ApiInfiniteGetQuery<RdcPart> => {
	return useInfiniteGetRequest([InventoryUrlPaths.Inventory, InventoryUrlPaths.RdcPartUrlPath], {
		...options,
		params: {
			...defaultRdcPartsQueryParams,
			...options?.params,
		},
	})
}

export const useRdcLocationPartSummary = (
	locationId: string | null
): ApiGetQuery<RdcPartSummary> => {
	return useGetRequest([InventoryUrlPaths.Inventory, InventoryUrlPaths.RdcPartSummaryUrlPath], {
		queryConfig: {
			enabled: !!locationId,
		},
		params: {
			location: locationId,
		},
	})
}

export const useRdcPartPost = (): ApiPostMutation<RdcPartPost> => {
	return usePostRequest([InventoryUrlPaths.Inventory, InventoryUrlPaths.RdcPartUrlPath], {
		keyToInvalidate: [InventoryUrlPaths.Inventory, InventoryUrlPaths.RdcPartUrlPath],
	})
}

export const useRdcPartsDelete = (): ApiDeleteMutation => {
	return useDeleteRequest([InventoryUrlPaths.Inventory, InventoryUrlPaths.RdcPartUrlPath], {
		keyToInvalidate: [InventoryUrlPaths.Inventory, InventoryUrlPaths.RdcPartUrlPath],
	})
}

export const useRdcPartBulkPost = (): ApiPostMutation<RdcPartBulkPost> => {
	return usePostRequest([InventoryUrlPaths.Inventory, InventoryUrlPaths.RdcPartBulkAdd], {
		keyToInvalidate: [InventoryUrlPaths.Inventory, InventoryUrlPaths.RdcPartUrlPath],
	})
}

export const useChemicalForecast = (
	forecastId: string | null | undefined
): ApiGetQuery<ChemicalForecast> => {
	return useGetRequest(
		[InventoryUrlPaths.Inventory, InventoryUrlPaths.ChemicalForecasts, forecastId ?? ""],
		{
			queryConfig: {
				enabled: !!forecastId,
			},
		}
	)
}

export const defaultChemicalForecastQueryParams: ChemicalForecastQueryParams = {
	search: null,
	ordering: null,
}

export const useChemicalForecasts = (
	options?: ApiInfiniteGetQueryOptions<ChemicalForecast, ChemicalForecastQueryParams>
): ApiInfiniteGetQuery<ChemicalForecast> => {
	return useInfiniteGetRequest(
		[InventoryUrlPaths.Inventory, InventoryUrlPaths.ChemicalForecasts],
		options
	)
}

export const useChemicalForecastItems = (
	search: ChemicalForecastItemsQueryParams["search"]
): ApiGetQuery<ChemicalForecastItem[]> => {
	return useGetRequest([InventoryUrlPaths.Inventory, InventoryUrlPaths.ChemicalForecast], {
		params: {
			search,
		},
		queryConfig: {
			// Endpoint returns a 400 if search is falsy.
			enabled: !!search,
		},
	})
}

export const useSubmitChemicalForecast = (): ApiPostMutation<ChemicalForecastPost, number> => {
	return usePostRequest(
		[
			InventoryUrlPaths.Inventory,
			InventoryUrlPaths.Submit,
			InventoryUrlPaths.ChemicalForecast,
		],
		{
			keyToInvalidate: InventoryUrlPaths.Inventory,
		}
	)
}

export const useUpdateChemicalForecast = (): ApiPatchMutation<ChemicalForecastPatch> => {
	return usePatchRequest(
		[
			InventoryUrlPaths.Inventory,
			InventoryUrlPaths.Submit,
			InventoryUrlPaths.ChemicalForecast,
		],
		{
			keyToInvalidate: InventoryUrlPaths.Inventory,
		}
	)
}

export const useLocationBins = (
	locationId: string | null | undefined,
	options?: ApiGetQueryOptions<BinLocation[], BinLocationQueryParams>
): ApiGetQuery<BinLocation[]> => {
	return useGetRequest(
		[
			InventoryUrlPaths.Inventory,
			InventoryUrlPaths.Locations,
			locationId ?? "",
			InventoryUrlPaths.Bins,
		],
		{
			...options,
			queryConfig: {
				...options?.queryConfig,
				enabled: !!locationId,
			},
		}
	)
}

export const useSimpleBins = (
	locationId: string | null | undefined,
	options?: ApiGetQueryOptions<BinLocation[], BinLocationQueryParams>
): ApiGetQuery<BinLocation[]> => {
	return useGetRequest(
		[
			InventoryUrlPaths.Inventory,
			InventoryUrlPaths.Locations,
			locationId ?? "",
			InventoryUrlPaths.SimpleBins,
		],
		{
			...options,
			queryConfig: {
				...options?.queryConfig,
				enabled: !!locationId,
			},
		}
	)
}

export const useDefaultOrderBins = (
	options?: ApiGetQueryOptions<PartDefaultBin[], DefaultOrderBinsQueryParams>
): ApiGetQuery<PartDefaultBin[]> => {
	return useGetRequest([InventoryUrlPaths.Inventory, InventoryUrlPaths.OrderBins], {
		...options,
		queryConfig: {
			...options?.queryConfig,
			enabled:
				!!options?.params?.location &&
				!!(options?.params?.partOrder || options?.params?.purchaseOrder),
		},
	})
}

export const useCheckPartOrderWarehouseStock = (
	partOrder: string | null
): ApiGetQuery<PartOrderWarehouseStock[]> => {
	return useGetRequest([InventoryUrlPaths.Inventory, InventoryUrlPaths.WarehouseStock], {
		params: {
			partOrder,
		},
		queryConfig: {
			enabled: !!partOrder,
		},
	})
}

export const useOverstockedParts = (
	locationId: string | null,
	options?: ApiGetQueryOptions<OverstockedPart[], OverstockedPartQueryParams>
): ApiGetQuery<OverstockedPart[]> => {
	return useGetRequest(
		[InventoryUrlPaths.Inventory, InventoryUrlPaths.OverStocked, locationId ?? ""],
		{
			...options,
			queryConfig: {
				...options?.queryConfig,
				enabled: !!locationId,
			},
		}
	)
}

export const usePartGroups = (
	options?: ApiInfiniteGetQueryOptions<PartGroup, void>
): ApiInfiniteGetQuery<PartGroup> => {
	return useInfiniteGetRequest(
		[InventoryUrlPaths.Inventory, InventoryUrlPaths.PartGroup],
		options
	)
}

export const useCreatePartGroup = (
	options?: ApiPostMutationOptions<PartGroupPost, PartGroup>
): ApiPostMutation<PartGroupPost, PartGroup> => {
	return usePostRequest<PartGroupPost, PartGroup>(
		[InventoryUrlPaths.Inventory, InventoryUrlPaths.PartGroup],
		{
			keyToInvalidate: InventoryUrlPaths.Inventory,
			...options,
		}
	)
}

export const useUpdatePartGroup = (
	options?: ApiPatchMutationOptions<PartGroupPatch>
): ApiPatchMutation<PartGroupPatch> => {
	return usePatchRequest([InventoryUrlPaths.Inventory, InventoryUrlPaths.PartGroup], {
		keyToInvalidate: InventoryUrlPaths.Inventory,
		...options,
	})
}

export const defaultPartGroupMembersQueryParams: PartGroupMemberQueryParams = {
	search: null,
	group: null,
}

export const usePartGroupMembers = (
	options?: ApiInfiniteGetQueryOptions<PartGroupMember, PartGroupMemberQueryParams>
): ApiInfiniteGetQuery<PartGroupMember> => {
	return useInfiniteGetRequest(
		[InventoryUrlPaths.Inventory, InventoryUrlPaths.PartGroupMember],
		{
			...options,
			params: {
				...defaultPartGroupMembersQueryParams,
				...options?.params,
			},
		}
	)
}

export const useAddPartGroupMember = (): ApiPostMutation<PartGroupMemberPost> => {
	return usePostRequest([InventoryUrlPaths.Inventory, InventoryUrlPaths.PartGroupMember], {
		keyToInvalidate: InventoryUrlPaths.Inventory,
	})
}

export const useRemovePartGroupMember = (): ApiDeleteMutation => {
	return useDeleteRequest([InventoryUrlPaths.Inventory, InventoryUrlPaths.PartGroupMember], {
		keyToInvalidate: InventoryUrlPaths.Inventory,
	})
}

export const defaultPartGroupDiscountQueryParams: PartGroupDiscountQueryParams = {
	group: null,
	search: null,
}

export const usePartGroupDiscounts = (
	options?: ApiInfiniteGetQueryOptions<PartGroupDiscount, PartGroupDiscountQueryParams>
): ApiInfiniteGetQuery<PartGroupDiscount> => {
	return useInfiniteGetRequest(
		[InventoryUrlPaths.Inventory, InventoryUrlPaths.PartGroupDiscount],
		{
			...options,
			params: {
				...defaultPartGroupDiscountQueryParams,
				...options?.params,
			},
		}
	)
}

export const useAddPartGroupDiscount = (): ApiPostMutation<PartGroupDiscountPost> => {
	return usePostRequest([InventoryUrlPaths.Inventory, InventoryUrlPaths.PartGroupDiscount], {
		keyToInvalidate: InventoryUrlPaths.Inventory,
	})
}

export const useUpdatePartGroupDiscount = (): ApiPatchMutation<PartGroupDiscountPatch> => {
	return usePatchRequest([InventoryUrlPaths.Inventory, InventoryUrlPaths.PartGroupDiscount], {
		keyToInvalidate: InventoryUrlPaths.Inventory,
	})
}

export const useDeletePartGroupDiscount = (): ApiDeleteMutation => {
	return useDeleteRequest([InventoryUrlPaths.Inventory, InventoryUrlPaths.PartGroupDiscount], {
		keyToInvalidate: InventoryUrlPaths.Inventory,
	})
}

export const usePickListDocument = (
	pickListId: string | null | undefined
): [WorkOrderDocument | undefined, boolean] => {
	const [data, isLoading] = useGetRequest<WorkOrderDocument[]>(
		[
			InventoryUrlPaths.Inventory,
			InventoryUrlPaths.PickList,
			pickListId ?? "",
			InventoryUrlPaths.Documents,
		],
		{
			queryConfig: {
				enabled: !!pickListId,
			},
		}
	)

	return [data ? data[0] : undefined, isLoading]
}

export const useGetPickListDocumentById = (): ((
	pickListId: string
) => Promise<WorkOrderDocument | undefined>) => {
	const getDocument = async (pickListId: string): Promise<WorkOrderDocument | undefined> => {
		const response = await apiClient.get<CamelToSnakeCaseNested<WorkOrderDocument[]>>(
			buildUrl(
				[
					InventoryUrlPaths.Inventory,
					InventoryUrlPaths.PickList,
					pickListId,
					InventoryUrlPaths.Documents,
				].join("/") + "/"
			)
		)

		return response?.data ? unpythonify(response.data[0]) : undefined
	}

	return getDocument
}

export const useCompletePickList = (): ApiPostMutation<CompletePickListPost> => {
	return usePostRequest([InventoryUrlPaths.Inventory, InventoryUrlPaths.PickListComplete], {
		keyToInvalidate: [
			InventoryUrlPaths.Inventory,
			PartOrdersUrlPaths.PartOrders,
			PartOrdersUrlPaths.Parts,
		],
	})
}

export const useSetPickListInProgress = (): ApiPostMutation<SetPickListInProgressPost> => {
	return usePostRequest([InventoryUrlPaths.Inventory, InventoryUrlPaths.PickListInProgress], {
		keyToInvalidate: [
			InventoryUrlPaths.Inventory,
			PartOrdersUrlPaths.PartOrders,
			PartOrdersUrlPaths.Parts,
		],
	})
}

export const useReassignPickList = (): ApiPostMutation<ReassignPickListPost> => {
	return usePostRequest([InventoryUrlPaths.Inventory, InventoryUrlPaths.PickListReassign], {
		keyToInvalidate: [
			InventoryUrlPaths.Inventory,
			PartOrdersUrlPaths.PartOrders,
			PartOrdersUrlPaths.Parts,
		],
	})
}

export const defaultInventoryTransactionsQueryParams: InventoryTransactionQueryParams = {
	part: null,
	bin: null,
	location: null,
	search: null,
	ordering: null,
}

export const useInventoryTransactions = (
	options: ApiInfiniteGetQueryOptions<InventoryTransaction, InventoryTransactionQueryParams>
): ApiInfiniteGetQuery<InventoryTransaction> => {
	return useInfiniteGetRequest([InventoryUrlPaths.Inventory, InventoryUrlPaths.Transactions], {
		...options,
		params: {
			...defaultInventoryTransactionsQueryParams,
			...options?.params,
		},
	})
}

export const useExportInventoryTransactions = (
	params?: Partial<InventoryTransactionQueryParams>
): DataExport => {
	return useDataExport([InventoryUrlPaths.Inventory, InventoryUrlPaths.Transactions], params)
}

export const useBinManagementBins = (
	options: ApiInfiniteGetQueryOptions<BinManagementBin, BinManagementBinQueryParams>
): ApiInfiniteGetQuery<BinManagementBin> => {
	return useInfiniteGetRequest(
		[InventoryUrlPaths.Inventory, InventoryUrlPaths.LocationBins],
		options
	)
}

export const useCreateBin = (): ApiPostMutation<BinManagementBinPost> => {
	return usePostRequest([InventoryUrlPaths.Inventory, InventoryUrlPaths.LocationBins], {
		keyToInvalidate: InventoryUrlPaths.Inventory,
	})
}

export const useUpdateBin = (): ApiPatchMutation<BinManagementBinPatch> => {
	return usePatchRequest([InventoryUrlPaths.Inventory, InventoryUrlPaths.LocationBins], {
		keyToInvalidate: InventoryUrlPaths.Inventory,
	})
}

export const useLocationLevels = (
	locationId: string | null | undefined,
	options?: ApiInfiniteGetQueryOptions<LocationLevel, LocationLevelQueryParams>
): ApiInfiniteGetQuery<LocationLevel> => {
	return useInfiniteGetRequest(
		[
			InventoryUrlPaths.Inventory,
			InventoryUrlPaths.Locations,
			locationId ?? "",
			InventoryUrlPaths.Levels,
		],
		{
			...options,
			queryConfig: {
				enabled: !!locationId,
				...options?.queryConfig,
			},
		}
	)
}

export const useLocalParts = (
	options: ApiInfiniteGetQueryOptions<LocalPart, LocalPartQueryParams>
): ApiInfiniteGetQuery<LocalPart> => {
	return useInfiniteGetRequest(
		[InventoryUrlPaths.Inventory, InventoryUrlPaths.LocalPart],
		options
	)
}

export const useCreateInventorySheet = (): ApiPostMutation<CreateInventorySheetPost> => {
	return usePostRequest([
		InventoryUrlPaths.Inventory,
		InventoryUrlPaths.LocationInventoryCheatSheet,
	])
}

export const useLocationPartSummaries = (
	options: ApiInfiniteGetQueryOptions<LocationPartSummary, LocationPartSummaryQueryParams>
): ApiInfiniteGetQuery<LocationPartSummary> => {
	return useInfiniteGetRequest(
		[InventoryUrlPaths.Inventory, InventoryUrlPaths.Location, InventoryUrlPaths.Levels],
		options
	)
}

export const useCreateLocationPart = (): ApiPostMutation<LocationPartSummaryPost> => {
	return usePostRequest(
		[InventoryUrlPaths.Inventory, InventoryUrlPaths.Location, InventoryUrlPaths.Part],
		{
			keyToInvalidate: InventoryUrlPaths.Inventory,
		}
	)
}

export const useUpdateLocationPart = (): ApiPatchMutation<LocationPartSummaryPatch> => {
	return usePatchRequest(
		[InventoryUrlPaths.Inventory, InventoryUrlPaths.Location, InventoryUrlPaths.Part],
		{
			keyToInvalidate: InventoryUrlPaths.Inventory,
		}
	)
}

export const useInventoryAllocations = (
	options?: ApiGetQueryOptions<InventoryAllocation[], InventoryAllocationQueryParams>
): ApiGetQuery<InventoryAllocation[]> => {
	return useGetRequest([InventoryUrlPaths.Inventory, InventoryUrlPaths.Allocation], options)
}

export const useLocationPartOrders = (params: {
	partId: string
	locationId: string
}): ApiGetQuery<LocationPartOrders> => {
	return useGetRequest([InventoryUrlPaths.Inventory, InventoryUrlPaths.OpenOrders], {
		params: {
			part: params.partId,
			location: params.locationId,
		},
	})
}

export const useEmailLocationStock = (): ((locationId: string) => Promise<void>) => {
	const call = async (locationId: string): Promise<void> => {
		await apiClient.get(
			buildUrl(
				`${[
					InventoryUrlPaths.Inventory,
					InventoryUrlPaths.Location,
					InventoryUrlPaths.Stock,
					InventoryUrlPaths.Email,
				].join("/")}/?location=${locationId}`
			)
		)
	}

	return call
}

export const useEmailLocationAllocations = (): ((locationId: string) => Promise<void>) => {
	const call = async (locationId: string): Promise<void> => {
		await apiClient.get(
			buildUrl(
				`${[
					InventoryUrlPaths.Inventory,
					InventoryUrlPaths.Location,
					InventoryUrlPaths.Allocation,
					InventoryUrlPaths.Email,
				].join("/")}/?location=${locationId}`
			)
		)
	}

	return call
}

export const useEmailLocationInventory = (): ((locationId: string) => Promise<void>) => {
	return async (locationId: string): Promise<void> => {
		await apiClient.get(
			buildUrl(
				`${[InventoryUrlPaths.Inventory, InventoryUrlPaths.FullLocationExport].join(
					"/"
				)}/?location=${locationId}`
			)
		)
	}
}

export const useCycleCountParts = (
	locationId: string | null | undefined
): ApiGetQuery<CycleCountPart[]> => {
	return useGetRequest(
		[InventoryUrlPaths.Inventory, InventoryUrlPaths.Location, InventoryUrlPaths.CycleCount],
		{
			params: {
				location: locationId,
			},
			queryConfig: {
				enabled: !!locationId,
			},
		}
	)
}

export const useRequestCycleCountSheet = (): ApiPostMutation<RequestCycleCountSheetPost> => {
	return usePostRequest(
		[
			InventoryUrlPaths.Inventory,
			InventoryUrlPaths.Location,
			InventoryUrlPaths.CycleSheetRequest,
		],
		{
			keyToInvalidate: InventoryUrlPaths.Inventory,
		}
	)
}

export const useUploadCycleCountSheet = (): ((
	data: CycleSheetSubmissionPost
) => Promise<void>) => {
	const request = usePostRequest<FormData>(
		[
			InventoryUrlPaths.Inventory,
			InventoryUrlPaths.Location,
			InventoryUrlPaths.CycleSheetSubmission,
		],
		{
			keyToInvalidate: InventoryUrlPaths.Inventory,
		}
	)

	return async (data: CycleSheetSubmissionPost): Promise<void> => {
		const formData = new FormData()
		formData.append("location_id", data.locationId)
		formData.append("count_data", data.countData)

		await request(formData)
	}
}

/**
 * Get the restricted parts that a customer has permissions for.
 */
export const useCustomerRestrictedParts = (
	customerId: string | null | undefined
): ApiGetQuery<CustomerRestrictedPart[]> => {
	return useGetRequest<CustomerRestrictedPart[], CustomerRestrictedPartQueryParams>(
		[InventoryUrlPaths.Inventory, InventoryUrlPaths.CustomerRestrictedParts],
		{
			params: { customerId },
			queryConfig: { enabled: !!customerId },
		}
	)
}

/**
 * Get the customers that have permission for a restricted part.
 */
export const useRestrictedPartCustomers = (
	partId: string | null | undefined
): ApiGetQuery<CustomerRestrictedPart[]> => {
	return useGetRequest<CustomerRestrictedPart[], CustomerRestrictedPartQueryParams>(
		[InventoryUrlPaths.Inventory, InventoryUrlPaths.CustomerRestrictedParts],
		{
			params: { partId },
			queryConfig: { enabled: !!partId },
		}
	)
}

export const useAddRestrictedPartToCustomers = (): ApiPostMutation<
	AddCustomerRestrictedPartPost,
	number
> => {
	return usePostRequest(
		[InventoryUrlPaths.Inventory, InventoryUrlPaths.CustomerRestrictedParts],
		{ keyToInvalidate: InventoryUrlPaths.Inventory }
	)
}

export const useDeleteRestrictedParts = (): ApiDeleteMutation<
	CustomerRestrictedPartDelete,
	number
> => {
	return useDeleteRequest(
		[InventoryUrlPaths.Inventory, InventoryUrlPaths.CustomerRestrictedParts],
		{ keyToInvalidate: InventoryUrlPaths.Inventory }
	)
}

export const useInventoryTransactionInitialData =
	(): ApiGetQuery<InventoryTransactionInitialData> => {
		return useGetRequest([
			InventoryUrlPaths.Inventory,
			InventoryUrlPaths.InventoryTransactionInitial,
		])
	}

export const useInventoryTransactionBins = (): ApiPostMutation<
	InventoryTransactionBinsPost,
	InventoryTransactionBins
> => {
	return usePostRequest([InventoryUrlPaths.Inventory, InventoryUrlPaths.GetBinInformation])
}

export const useCreateInventoryTransaction =
	(): ApiPostMutation<CreateInventoryTransactionPost> => {
		return usePostRequest([InventoryUrlPaths.Inventory, InventoryUrlPaths.Transaction], {
			keyToInvalidate: InventoryUrlPaths.Inventory,
		})
	}

export const usePrintBinLabels = (): ((request: PrintBinLabelsRequest) => AxiosPromise<Blob>) => {
	return async (request: PrintBinLabelsRequest): Promise<AxiosResponse<Blob>> =>
		apiClient.get(
			buildUrl(
				`${[InventoryUrlPaths.Inventory, InventoryUrlPaths.LocationBinLabels].join(
					"/"
				)}/?${qs.stringify(pythonify(request), {
					arrayFormat: "comma",
				})}`
			)
		)
}

export type PythonifiedBin = {
	_id: number
	code: string
	is_active: boolean
}

export const useGetAllLocationBins = (): ((
	locationId: string
) => AxiosPromise<PythonifiedBin[]>) => {
	return async (locationId: string): Promise<AxiosResponse<PythonifiedBin[]>> =>
		apiClient.get(
			buildUrl(
				`${[
					InventoryUrlPaths.Inventory,
					InventoryUrlPaths.AllLocationBins,
					locationId,
				].join("/")}/`
			)
		)
}

export const useCreateLabel = (): ApiPostMutation<GenericLabelPost> => {
	return usePostRequest<GenericLabelPost, Blob>([
		InventoryUrlPaths.Inventory,
		InventoryUrlPaths.GenericLabels,
	])
}

export const useLocationPickListAssignments = (
	locationId: string | null | undefined,
	options?: ApiGetQueryOptions<UserMinimal[], LocationPickListAssignmentsQueryParams>
): ApiGetQuery<UserMinimal[]> => {
	return useGetRequest(
		[InventoryUrlPaths.Inventory, InventoryUrlPaths.PickListAssignments, locationId ?? ""],
		{
			...options,
			queryConfig: {
				enabled: !!locationId,
				...options?.queryConfig,
			},
		}
	)
}

export const useBillOfMaterials = (
	options?: ApiGetQueryOptions<BillOfMaterial[], BillOfMaterialQueryParams>
): ApiGetQuery<BillOfMaterial[]> => {
	return useGetRequest([InventoryUrlPaths.Inventory, InventoryUrlPaths.BillOfMaterials], options)
}

export const useCreateBillOfMaterials = (): ApiPostMutation<CreateBillOfMaterialPost, string> => {
	return usePostRequest([InventoryUrlPaths.Inventory, InventoryUrlPaths.BillOfMaterial], {
		keyToInvalidate: [
			InventoryUrlPaths.Inventory,
			InventoryUrlPaths.BillOfMaterial,
			InventoryUrlPaths.BillOfMaterials,
		],
	})
}

export const useUpdateBillOfMaterials = (): ApiPatchMutation<UpdateBillOfMaterialPatch> => {
	return usePatchRequest([InventoryUrlPaths.Inventory, InventoryUrlPaths.BillOfMaterial], {
		keyToInvalidate: [
			InventoryUrlPaths.Inventory,
			InventoryUrlPaths.BillOfMaterial,
			InventoryUrlPaths.BillOfMaterials,
		],
	})
}

export const useCompleteBillOfMaterial = (): ApiPostMutation<CompleteBillOfMaterialPost> => {
	return usePostRequest([InventoryUrlPaths.Inventory, InventoryUrlPaths.BillOfMaterials], {
		keyToInvalidate: [
			InventoryUrlPaths.Inventory,
			InventoryUrlPaths.BillOfMaterial,
			InventoryUrlPaths.BillOfMaterials,
		],
	})
}

export const useManufacturedParts = (): ApiGetQuery<ManufacturedPart[]> => {
	return useGetRequest([InventoryUrlPaths.Inventory, InventoryUrlPaths.ManufacturedParts])
}

export const useCreateManufacturedPart = (): ApiPostMutation<CreateManufacturedPartPost> => {
	return usePostRequest([InventoryUrlPaths.Inventory, InventoryUrlPaths.ManufacturedParts], {
		keyToInvalidate: InventoryUrlPaths.Inventory,
	})
}

export const useUpdateManufacturedPart = (): ApiPatchMutation<UpdateManufacturedPartPatch> => {
	return usePatchRequest([InventoryUrlPaths.Inventory, InventoryUrlPaths.ManufacturedParts], {
		keyToInvalidate: InventoryUrlPaths.Inventory,
	})
}

export const useDeleteManufacturedPart = (): ApiDeleteMutation => {
	return useDeleteRequest([InventoryUrlPaths.Inventory, InventoryUrlPaths.ManufacturedParts], {
		keyToInvalidate: InventoryUrlPaths.Inventory,
	})
}

export const usePartAvailability = (
	options: ApiGetQueryOptions<BinPart[], BinPartQueryParams>
): ApiGetQuery<BinPart[]> => {
	return useGetRequest([InventoryUrlPaths.Inventory, InventoryUrlPaths.PartAvailability], {
		...options,
		queryConfig: {
			...options.queryConfig,
			enabled: !!options.params?.locationId || !!options.params?.partId,
		},
	})
}

export const useReplacePart = (): ApiPostMutation<ReplacePartPost, ReplacementSuccess> => {
	return usePostRequest([InventoryUrlPaths.Inventory, InventoryUrlPaths.PartReplacement])
}

export const useInventoryRequests = (
	options?: ApiInfiniteGetQueryOptions<InventoryRequestListItem, InventoryRequestQueryParams>
): ApiInfiniteGetQuery<InventoryRequestListItem> => {
	return useInfiniteGetRequest(
		[InventoryUrlPaths.Inventory, InventoryUrlPaths.InventoryRequests],
		options
	)
}

export const useInventoryRequest = (
	id: string | null | undefined
): ApiGetQuery<InventoryRequest> => {
	return useGetRequest(
		[InventoryUrlPaths.Inventory, InventoryUrlPaths.InventoryRequestDetail, id || ""],
		{
			queryConfig: {
				enabled: !!id,
			},
		}
	)
}

export const useCreateInventoryRequestLine = (
	inventoryRequestId: string
): ApiPostMutation<CreateInventoryRequestLinePost> => {
	return usePostRequest(
		[
			InventoryUrlPaths.Inventory,
			InventoryUrlPaths.InventoryRequests,
			inventoryRequestId,
			InventoryUrlPaths.Lines,
		],
		{
			keyToInvalidate: InventoryUrlPaths.Inventory,
		}
	)
}

export const useUpdateInventoryRequestLine = (
	inventoryRequestId: string
): ApiPatchMutation<UpdateInventoryRequestLinePatch> => {
	return usePatchRequest(
		[
			InventoryUrlPaths.Inventory,
			InventoryUrlPaths.InventoryRequests,
			inventoryRequestId,
			InventoryUrlPaths.Lines,
		],
		{
			keyToInvalidate: InventoryUrlPaths.Inventory,
		}
	)
}

export const useCompleteInventoryRequest = (requestId: string): ApiPostMutation<void> => {
	return usePostRequest(
		[
			InventoryUrlPaths.Inventory,
			InventoryUrlPaths.InventoryRequests,
			requestId,
			InventoryUrlPaths.Submit,
		],
		{
			keyToInvalidate: InventoryUrlPaths.Inventory,
		}
	)
}

export const useExportInventoryRequestLines = (requestId: string): DataExport => {
	return useDataExport([
		InventoryUrlPaths.Inventory,
		InventoryUrlPaths.InventoryRequests,
		requestId,
		InventoryUrlPaths.Lines,
	])
}

export const useCancelInventoryRequest = (
	requestId: string
): ApiPatchMutation<CancelInventoryRequestPatch> => {
	return usePatchRequest(
		[InventoryUrlPaths.Inventory, InventoryUrlPaths.InventoryRequestCreate, requestId],
		{
			keyToInvalidate: InventoryUrlPaths.Inventory,
		}
	)
}

export const useCreateInventoryRequest = (): ApiPostMutation<CreateInventoryRequestPost> => {
	return usePostRequest(
		[InventoryUrlPaths.Inventory, InventoryUrlPaths.InventoryRequestCreate],
		{
			keyToInvalidate: InventoryUrlPaths.Inventory,
		}
	)
}

export const usePartBins = (partId: string | null | undefined): ApiGetQuery<PartInBin[]> => {
	return useGetRequest([InventoryUrlPaths.Inventory, InventoryUrlPaths.BinParts, partId ?? ""], {
		queryConfig: {
			enabled: !!partId,
		},
	})
}
