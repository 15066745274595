import React from "react"

import { css, Theme } from "@emotion/react"

export const ContentWrapper: React.FC = React.memo(({ children }) => {
	return <div css={style}>{children}</div>
})

const style = (theme: Theme) => css`
	max-width: 85rem;
	margin-left: auto;
	margin-right: auto;
	padding: 0 1.5rem;
	${theme.breakpoints.only("sm")} {
		padding: 0 0.75rem;
	}
	${theme.breakpoints.only("xs")} {
		padding: 0 0.5rem;
	}
`
