import { APPLICATION, LineItemType, PriceOverrideReason } from "../portal-apps"

/**
 * A generic line item interface to be used when CRUDing them for multiple contexts,
 * ie Part Order, Purchase Order, Quote/ERP, etc.
 */
export interface GenericLineItem {
	id?: string
	lineTypeId: LineItemType
	part: {
		id: string
		description: string
		partNumber: string
		partFamily: string | null
		weight: number | null
	} | null
	description: string
	quantity: number
	/**
	 * The actual price to be charged and end up on the Line Item's price in the DB.
	 */
	finalPrice: number
	/**
	 * The base price for the line. Price before any customer discounts or overrides. We may or may not
	 * have this information. Typically filled in from the line pricing call.
	 */
	basePrice: number | null
	/**
	 * User-edited price. Amount that the user wants to make the line.
	 */
	requestedPrice: number | null
	/**
	 * Tax rate, expressed as decimal, ie 0.07 for "7%".
	 */
	taxRate: number
	/**
	 * Qty times the final price, but before taxes.
	 */
	subtotal: number
	/**
	 * `PriceOverrideReason` object.
	 */
	priceOverrideReason: PriceOverrideReason | null
	/**
	 * Permission application required to edit this.
	 */
	overridePermission: {
		id: string
		description: APPLICATION
	} | null
	/**
	 * Used if the price override reason is "Other".
	 */
	reasonComment: string | null
	/**
	 * Was this line item generated by a backend system call?
	 */
	systemGeneratedLine: boolean
	/**
	 * If the line item was returned from a backend call, we need to store what it's price was
	 * originally.
	 */
	originalSystemGeneratedPrice: number | null
	/**
	 * If this line item has an override request that has not yet been approved or denied.
	 */
	overrideApprovalPending?: boolean
	/**
	 * For use with dispatch: Is this line item billable?
	 */
	isBillable?: boolean
	/**
	 * For use with dispatch: Is this line item under warranty?
	 */
	isUnderWarranty?: boolean
}

/**
 * A GenericLineItem with a base price always there. Useful because we need an interface that's as
 * small as possible for going IN to the line item editor modal, because it has to be assembled
 * from the info that comes back from all the different ways we get line items. But going OUT of
 * the modal though, we can add all this extra data that comes back from the line pricing call.
 */
export type GenericLineItemWithBasePrice = Omit<GenericLineItem, "basePrice"> & {
	basePrice: NonNullable<GenericLineItem["basePrice"]>
}

export type GenericLineItemWithId = Omit<GenericLineItem, "id"> & {
	id: NonNullable<GenericLineItem["id"]>
}

export type GenericLineItemWithPart = Omit<GenericLineItem, "part"> & {
	part: NonNullable<GenericLineItem["part"]>
}

/**
 * Test if two generic line items are for the same item/part/product/whatever.
 */
export const lineItemsAreEqual = (a: GenericLineItem, b: GenericLineItem): boolean => {
	// If they're not the same type ID, then they're definitely not the same.
	if (a.lineTypeId !== b.lineTypeId) {
		return false
	}
	// If they both have parts, then we can go by their part IDs.
	if (!!a.part && !!b.part) {
		return a.part.id === b.part.id
	}
	// Otherwise just go by the description text. Necessary because of line items like
	// Outside Purchase, which has customized descriptions, so you could add multiple
	// of these with the same type but are actually different things.
	return a.description === b.description
}

/**
 * Test if a `GenericLineItem` is `GenericLineItemWithId`.
 */
export const genericLineItemHasId = (
	lineItem: GenericLineItem
): lineItem is GenericLineItemWithId => {
	return ["number", "string"].includes(typeof lineItem.id)
}

/**
 * Test if a `GenericLineItem` is `GenericLineItemWithBasePrice`.
 */
export const genericLineItemHasBasePrice = (
	lineItem: GenericLineItem
): lineItem is GenericLineItemWithBasePrice => {
	return ["number", "string"].includes(typeof lineItem.basePrice)
}

/**
 * Line item types where there is no set price coming from the server, it's always up to the
 * user to set it here here in the UI. These should always have an editable Price field, there
 * is no need for price overriding.*
 */
export const lineTypesRequireUserPrice: (LineItemType | null)[] = [
	LineItemType.OutsidePurchase,
	LineItemType.SubContractCharge,
]

/**
 * Line item types where we need to collect a description in order to use.
 */
export const lineTypesRequireDescription: (LineItemType | null)[] = [LineItemType.OutsidePurchase]

/**
 * The accessorial line types. Ideally this should live in the backend somehow,
 * not on the frontend.
 */
export const lineTypesAccessorials: (LineItemType | null)[] = [
	LineItemType.AppointmentNotification,
	LineItemType.TankEndorsement,
	LineItemType.StopCharge,
	LineItemType.Redelivery,
	LineItemType.ProtectiveService,
	LineItemType.PrivateResidence,
	LineItemType.PalletJack,
	LineItemType.LimitedAccess,
	LineItemType.JobSiteDelivery,
	LineItemType.InsideDeliveryPickup,
	LineItemType.HighCostRegion,
	LineItemType.HandlingFee,
	LineItemType.HazmatFee,
	LineItemType.GuaranteedDelivery,
	LineItemType.LiftGate,
]

/**
 * Line item types that are in the general freight category. So freight, fuel surcharge, and
 * accessorials.
 */
export const lineTypesFreightish: (LineItemType | null)[] = [
	LineItemType.Freight,
	LineItemType.FuelSurcharge,
	...lineTypesAccessorials,
]

/**
 * Line types where you can only ever have quantity of 1. At the moment it's just the same
 * as the freightish list.
 */
export const lineTypesOneOnly: (LineItemType | null)[] = [...lineTypesFreightish]

/**
 * These are the only line item types that can be on the same order more than once.
 */
export const lineTypesRepeatable: (LineItemType | null)[] = [
	LineItemType.Parts,
	LineItemType.OutsidePurchase,
]

/**
 * These accessorials have a price that cannot be overridden by the user.
 */
export const lineTypesCannotOverridePrice: (LineItemType | null)[] = [...lineTypesAccessorials]
