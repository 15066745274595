import React, { useEffect } from "react"

import { css } from "@emotion/react"
import { Link, useLocation } from "react-router-dom"

import { Box, Divider, Icon, Modal, Paragraph, usePrevious } from "@ncs/web-legos"

import { Logo } from "./Logo"
import { NcsSocial } from "./NcsSocial"
import { SiteNavigation } from "./SiteNavigation"

export interface MobileNavModalProps {
	isOpen: boolean
	onClose: () => void
}

export const MobileNavModal: React.FC<MobileNavModalProps> = React.memo(({ isOpen, onClose }) => {
	const location = useLocation()

	const prevLocation = usePrevious(location)
	useEffect(() => {
		// Close the nav modal if you click a link.
		if (location.key !== prevLocation.key) {
			onClose()
		}
	}, [onClose, location, prevLocation])

	return (
		<Modal isOpen={isOpen} onClose={onClose} fullScreen>
			<Link to="/">
				<Logo maxWidth="7rem" />
			</Link>

			<SiteNavigation css={navStyle} location="modal" />

			<Divider my={3} />

			<div css={contactInfoStyle}>
				<Box display="flex" mb={1}>
					<Icon icon="user-headset" />
					<Paragraph ml={0.75}>1-833-NCS-WASH</Paragraph>
				</Box>
				<NcsSocial />
				<Box my={2}>
					<Paragraph>1500 SE 37th St.</Paragraph>
					<Paragraph>Grimes, IA 50111</Paragraph>
					<Paragraph>1-833-NCS-Wash</Paragraph>
				</Box>
			</div>
		</Modal>
	)
})

const navStyle = css`
	margin-top: 2rem;
	li {
		margin-bottom: 1.5rem;
		a {
			font-size: 1.5rem;
			text-decoration: none;
			text-transform: uppercase;
		}
	}
`
const contactInfoStyle = css`
	opacity: 0.5;
`
