import { AxiosError } from "axios"

import { sentence } from "@ncs/ts-utils"

export const isAxiosError = (
	e: unknown
): e is AxiosError<{ reason?: string; message?: string; detail?: string } | string> => {
	return !!e && (e as AxiosError).isAxiosError !== undefined
}

export const isDrfSerializerError = (
	e: AxiosError
): e is AxiosError<{
	[key: string]: string[]
}> => {
	return (
		!!e.response?.data && Object.values(e.response.data).every((value) => Array.isArray(value))
	)
}

const defaults = {
	opener: "Error",
	closer: "Please contact support if the issue persists",
}

export const makeApiErrorMessage = (
	e: unknown,
	options?: {
		opener?: string
		closer?: string
	}
): string => {
	if (isAxiosError(e)) {
		let serverMessage = ""

		if (typeof e.response?.data === "string") {
			// Check length. An HTML response will be long, and a full debug error log will be super duper long.
			if (e.response.data.length < 100) {
				// This is the backend explicitly returning an error Response with just a plain string.
				serverMessage = e.response.data
			}
		} else if (isDrfSerializerError(e)) {
			// A backend DRF serializer returned errors.
			const messages: string[] = []
			const dataEntries = Object.entries(e.response?.data ?? {})

			dataEntries.forEach(([key, errors]) => {
				messages.push(
					sentence(`${key !== "non_field_errors" ? `${key}: ` : ""}${errors.join(", ")}`)
				)
			})

			serverMessage = messages.join("")
		} else if (e.response?.data?.reason) {
			// Backend returned error Response with dict with "reason" key.
			serverMessage = e.response.data.reason
		} else if (e.response?.data?.message) {
			// Backend returned error Response with dict with "message" key.
			serverMessage = e.response.data.message
		} else if (e.response?.data?.detail) {
			// Backend returned error Response with dict with "detail" key.
			serverMessage = e.response.data.detail
		}

		const result = `${sentence(options?.opener ?? defaults.opener)}${sentence(e.message)}${
			serverMessage ? `The server said: "${sentence(serverMessage, false)}" ` : ""
		}${sentence(options?.closer ?? defaults.closer)}`

		return result
	} else {
		if (e instanceof Error) {
			return e.message
		}
		return "There was an error communicating with the server. "
	}
}
