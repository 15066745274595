import React, {
	ChangeEvent,
	FocusEventHandler,
	ReactElement,
	useEffect,
	useMemo,
	useRef,
} from "react"

import { css, SerializedStyles } from "@emotion/react"

import { accessBoolean, accessString, BooleanAccessor, StringAccessor } from "@ncs/ts-utils"

import { cssMixins } from "../../util"
import { Icon } from "../typography"
import { FieldContainer, FieldContainerProps } from "./FieldContainer"

export interface SelectProps<Option> extends FieldContainerProps {
	value: string | null
	initialValue?: string | null
	onChange: (newValue: string | null, selectedOption?: Option) => void
	onChangeEvent?: (e: ChangeEvent<HTMLSelectElement>) => void
	onBlur?: FocusEventHandler<HTMLSelectElement>
	options: Option[]
	/** @default "value" */
	valueAccessor?: StringAccessor<Option>
	/** @default "text" */
	textAccessor?: StringAccessor<Option>
	/** @default "disabled" */
	disabledAccessor?: BooleanAccessor<Option>
	showNoSelectionOption?: boolean
	disableNoSelectionOption?: boolean
	/** @default "—" */
	noSelectionOptionText?: string
	nullNoSelectionValue?: boolean
	disabled?: boolean
	sortOptions?: boolean
	selectCss?: SerializedStyles
	isLoading?: boolean
}

/**
 * When you make some type of selector that's based on the Select component, you can
 * have its props extend these here. They're the props from the original Select component
 * that should bubble all the way up and be present when you use your new selector.
 *
 * You can also pass in a new type for value if you're option values aren't strings. But
 * be sure to also use the `valueAccessor` prop to tell Select how to go from the non-string
 * to a string (because the actual HTML select only wants strings for value).
 */
export interface ExtendableSelectProps<
	Option = {
		value: string
		text: string
	},
	Value = string,
> extends Omit<SelectProps<Option>, "options" | "value" | "onChange"> {
	value: Value | null
	onChange: (newValue: Value | null, newOption?: Option) => void
}

export const SelectWithoutMemo = <Option,>({
	value,
	initialValue,
	onChange,
	onChangeEvent,
	onBlur,
	options,
	valueAccessor = "value" as keyof Option,
	textAccessor = "text" as keyof Option,
	disabledAccessor = "disabled" as keyof Option,
	showNoSelectionOption = true,
	disableNoSelectionOption = true,
	noSelectionOptionText = "—",
	nullNoSelectionValue = true,
	disabled,
	sortOptions,
	isLoading,
	selectCss,
	...rest
}: SelectProps<Option>): ReactElement => {
	const hasSetInitialValue = useRef(false)

	useEffect(() => {
		if (
			hasSetInitialValue.current === false &&
			!!initialValue &&
			value == null &&
			!!onChange
		) {
			const initialOption = options.find((option) => {
				const optionValue = accessString(option, valueAccessor)

				return typeof initialValue === "string" && initialValue === optionValue
			})

			if (initialOption) {
				hasSetInitialValue.current = true
				onChange(initialValue, initialOption)
			}
		}
	}, [initialValue, onChange, options, valueAccessor, value])

	const handleChange = (e: ChangeEvent<HTMLSelectElement>) => {
		const newValue = e.target.value

		if (onChangeEvent) {
			onChangeEvent(e)
		} else if (onChange) {
			// Find the option user selected in the options array and pass that out too.
			const selectedOption = options.find((option) => {
				const optionValue = accessString(option, valueAccessor)

				return newValue === optionValue
			})

			// The selected option will almost always be defined, but in the case where user
			// re-selects the empty value, then this selected option will be undefined.
			onChange(newValue || (nullNoSelectionValue ? null : newValue), selectedOption)
		}
	}

	const preparedOptions = useMemo(() => {
		return sortOptions ?
				options.sort((a, b) => {
					const aText = accessString(a, textAccessor).toLowerCase()
					const bText = accessString(b, textAccessor).toLowerCase()

					return aText > bText ? 1 : -1
				})
			:	options
	}, [options, sortOptions, textAccessor])

	return (
		<FieldContainer fillContainer={false} {...rest}>
			<div css={selectContainerStyle}>
				<select
					css={[selectStyle, cssMixins.nonMuiFormElementStyle, selectCss]}
					value={value ?? ""}
					onChange={handleChange}
					onBlur={onBlur}
					disabled={disabled || isLoading}
				>
					{showNoSelectionOption && (
						<option value="" disabled={disableNoSelectionOption}>
							{noSelectionOptionText}
						</option>
					)}
					{preparedOptions.map((option) => {
						const optionValue = accessString(option, valueAccessor)
						const text = accessString(option, textAccessor)
						const optionIsDisabled = accessBoolean(
							option,
							disabledAccessor,
							false,
							true
						)

						return (
							<option
								key={optionValue}
								value={optionValue}
								disabled={optionIsDisabled}
							>
								{text}
							</option>
						)
					})}
				</select>
				<div css={iconContainerStyle}>
					<Icon
						icon={isLoading ? "spinner-third" : "caret-down"}
						family={isLoading ? undefined : "solid"}
						spin={isLoading}
					/>
				</div>
			</div>
		</FieldContainer>
	)
}

// https://github.com/DefinitelyTyped/DefinitelyTyped/issues/37087#issuecomment-656596623
export const Select = React.memo(SelectWithoutMemo) as typeof SelectWithoutMemo

const selectContainerStyle = css`
	position: relative;
	width: inherit;
`
const selectStyle = css`
	appearance: none;
	border-radius: 0;
	padding-right: 2.25rem;
	width: 100%;
`
const iconContainerStyle = css`
	position: absolute;
	right: 0.75rem;
	top: 50%;
	transform: translateY(-50%);
	opacity: 0.5;
	pointer-events: none;
`
