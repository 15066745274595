import React from "react"

import { css, Theme, useTheme } from "@emotion/react"

import { Icon, Paragraph } from "@ncs/web-legos"

export interface NcsSocialProps {
	textColor?: "light" | "dark"
}

export const NcsSocial: React.FC<NcsSocialProps> = ({ textColor = "dark" }) => {
	const theme = useTheme()

	const colorStyle = css`
		p {
			color: ${textColor === "dark" ? theme.palette.text.primary : "white"};
		}
		li a {
			color: ${textColor === "dark" ? theme.palette.text.primary : "white"};
		}
	`
	return (
		<div css={colorStyle}>
			<Paragraph mb={0.25}>Follow Us</Paragraph>

			<ul css={iconListStyle}>
				<li>
					<a
						href="https://www.linkedin.com/company/national-carwash-solutions"
						target="_blank"
						rel="noopener noreferrer"
					>
						<Icon icon="linkedin" family="brands" />
					</a>
				</li>
				<li>
					<a
						href="https://www.youtube.com/channel/UC-A7544E_dtbjHBjA-qPRPA"
						target="_blank"
						rel="noopener noreferrer"
					>
						<Icon icon="youtube" family="brands" />
					</a>
				</li>
				{/* TODO - Does this exist? Ask Bobby? */}
				{/* <li>
					<a href="/" target="_blank" rel="noopener noreferrer">
						<Icon icon="twitter" family="brands" />
					</a>
				</li> */}
				<li>
					<a
						href="https://www.instagram.com/nationalcarwashsolutions/"
						target="_blank"
						rel="noopener noreferrer"
					>
						<Icon icon="instagram" family="brands" />
					</a>
				</li>
				<li>
					<a
						href="https://www.facebook.com/nationalcarwashsolutions/"
						target="_blank"
						rel="noopener noreferrer"
					>
						<Icon icon="facebook" family="brands" />
					</a>
				</li>
			</ul>
		</div>
	)
}

const iconListStyle = (theme: Theme) => css`
	display: flex;
	li {
		margin-right: 0.75rem;
		&:last-of-type {
			margin-right: 0;
		}
	}
`
