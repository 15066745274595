import React, { memo } from "react"

import { css, Theme } from "@emotion/react"
import { Link, useHistory } from "react-router-dom"

import { CustomerPart, isNonParentCustomerPart, NonParentCustomerPart } from "@ncs/ncs-api"
import { Box, Button, Card, CardProps, Heading, Paragraph, PartImage, Price } from "@ncs/web-legos"

import { useShopContext } from "~/contexts"

export interface PartCardProps extends CardProps {
	part: CustomerPart
	onAddToCart: (partId: string) => void
}

export const PartCard: React.FC<PartCardProps> = memo(({ part, onAddToCart, ...rest }) => {
	const history = useHistory()
	const [, shopDispatch] = useShopContext()

	const handleAddToCart = (partToAdd: NonParentCustomerPart) => {
		shopDispatch({
			type: "add part to cart",
			payload: { part: partToAdd },
		})

		// Fire the passed in handler to open to the added to cart modal.
		onAddToCart(partToAdd.id)
	}

	return (
		<Card {...rest}>
			<Box
				display="flex"
				flexDirection="column"
				justifyContent="space-between"
				height="inherit"
			>
				<div css={contentStyle}>
					<div>
						<Link to={`/shop/parts/${part.id}`} css={headingLinkStyle}>
							<Heading variant="h3" mb={0.5} css={headingStyle}>
								{part.title}
							</Heading>
						</Link>
						{isNonParentCustomerPart(part) ?
							<>
								<Paragraph mb={0.5} color="secondary">
									#{part.onlinePartNumber}
								</Paragraph>
								<Price price={part.netPrice} nonDiscountedPrice={part.price} />
							</>
						:	<Paragraph small color="secondary">
								Multiple options available
							</Paragraph>
						}
					</div>

					<Link to={`/shop/parts/${part.id}`}>
						<PartImage
							src={part.imageUrl}
							css={partImageStyle}
							alt={part.title}
							ml={2}
							xsProps={{
								ml: 1,
							}}
						/>
					</Link>
				</div>

				{isNonParentCustomerPart(part) ?
					<Button
						variant="primary-cta"
						icon="shopping-cart"
						iconFamily="solid"
						onClick={() => handleAddToCart(part)}
						fillContainer
						containerProps={{
							mt: 1,
						}}
					>
						Add To Cart
					</Button>
				:	<Button
						variant="primary-cta"
						onClick={() => history.push(`/shop/parts/${part.id}`)}
						fillContainer
						containerProps={{
							mt: 1,
						}}
					>
						Choose Options
					</Button>
				}
			</Box>
		</Card>
	)
})

const contentStyle = css`
	display: flex;
	justify-content: space-between;
`
const headingLinkStyle = css`
	text-decoration: none;
	&:hover {
		text-decoration: underline;
	}
`
const headingStyle = css`
	overflow-wrap: break-word;
	word-break: break-word;
	hyphens: auto;
`
const partImageStyle = (theme: Theme) => css`
	width: 10rem;
	${theme.breakpoints.only("md")} {
		width: 8rem;
	}
`
