import { useCallback, useEffect, useMemo, useState } from "react"

import { Link, useHistory } from "react-router-dom"
import { CellProps, Column } from "react-table"

import {
	CustomerPartOrderLineItem,
	isNonParentCustomerPart,
	useCustomerPartOrder,
	useCustomerParts,
} from "@ncs/ncs-api"
import { extractNumber, formatCurrency, formatDate } from "@ncs/ts-utils"
import {
	Box,
	Button,
	EmptyValueDash,
	GridContainer,
	GridItem,
	Label,
	LabeledData,
	Modal,
	ParagraphList,
	PartImage,
	Table,
} from "@ncs/web-legos"

import { useShopContext } from "~/contexts"

export interface OrderDetailModalProps {
	isOpen: boolean
	onClose: () => void
	orderId: string | null
}

export const OrderDetailModal: React.FC<OrderDetailModalProps> = ({
	isOpen,
	onClose,
	orderId,
}) => {
	const history = useHistory()
	const [order, orderLoading] = useCustomerPartOrder(orderId)
	const [, shopDispatch] = useShopContext()
	const [isReordering, setIsReordering] = useState(false)
	const [hasReordered, setHasReordered] = useState(false)

	const handleClose = () => {
		onClose()
		setIsReordering(false)
		setHasReordered(false)
	}

	// Note that sometimes there will be line items but none are reorderable.
	const reorderIds = useMemo(() => {
		const results: string[] = []

		if (order) {
			order.lineItems.forEach((item) => {
				if (item.onlinePartId) {
					results.push(String(item.onlinePartId))
				}
			})
		}

		return results
	}, [order])

	const partsQuery = useCustomerParts({
		params: {
			onlinePartId: reorderIds,
		},
		queryConfig: {
			enabled: reorderIds.length > 0,
		},
		pageSize: 999, // Make this arbitrarily long because we want to make sure to grab all the parts in the order.
	})

	const addReorderPartsToCart = useCallback(() => {
		const cartParts = partsQuery.data.flatMap((part) => {
			const quantity = order?.lineItems.find((item) => item.part?.id === part.part?.id)
				?.quantity

			if (isNonParentCustomerPart(part)) {
				return [
					{
						part,
						quantity: quantity ? Number(quantity) : 1,
					},
				]
			} else {
				return []
			}
		})

		shopDispatch({
			type: "add parts to cart",
			payload: cartParts,
		})
	}, [order?.lineItems, partsQuery.data, shopDispatch])

	const onReorder = () => {
		// Because the query for the parts to reorder might not be ready yet, flip this to
		// true and then we'll trigger the actual ordering with useEffect when the query's ready.
		setIsReordering(true)
	}

	useEffect(() => {
		// If this is true, then we were waiting for the parts data to be ready and we can
		// go ahead and add to the cart.
		if (isReordering && partsQuery.data.length > 0) {
			addReorderPartsToCart()
			setIsReordering(false)
			setHasReordered(true)
		}
		// In the weird case that we did have online part IDs to reorder, but the parts data
		// response is still empty, we can be stuck in our reordering state. Fix that here.
		if (isReordering && !partsQuery.isLoading && partsQuery.data.length === 0) {
			setIsReordering(false)
		}
	}, [addReorderPartsToCart, isReordering, partsQuery.data.length, partsQuery.isLoading])

	const onViewPart = useCallback(
		(id?: number | null) => {
			if (id) {
				history.push(`/shop/parts/${id}`)
			}
		},
		[history]
	)

	const columns: Column<CustomerPartOrderLineItem>[] = useMemo(
		() => [
			{
				id: "Image",
				Header: "",
				disableSortBy: true,
				Cell: ({ row: { original: lineItem } }: CellProps<CustomerPartOrderLineItem>) => {
					return (
						lineItem.onlinePartId ?
							<Link to={`/shop/parts/${lineItem.onlinePartId}`}>
								<PartImage
									src={lineItem.onlinePart?.imageUrl}
									alt={lineItem.onlinePart?.title ?? ""}
									maxWidth="5rem"
								/>
							</Link>
						: lineItem.part ?
							<PartImage
								src={lineItem.onlinePart?.imageUrl}
								alt={lineItem.onlinePart?.title ?? ""}
								maxWidth="5rem"
							/>
						:	null
					)
				},
			},
			{
				Header: "Product #",
				accessor: (lineItem) => lineItem.onlinePart?.onlinePartNumber,
				disableSortBy: true,
			},
			{
				Header: "Description",
				accessor: (lineItem) => lineItem.onlinePart?.title ?? lineItem.description,
			},
			{
				Header: "Qty",
				accessor: (lineItem) => extractNumber(lineItem.quantity),
			},
			{
				Header: "Unit Price",
				accessor: (lineItem) => formatCurrency(lineItem.unitPrice),
			},
			{
				Header: "Total Price",
				accessor: (lineItem) =>
					formatCurrency(extractNumber(lineItem.quantity) * Number(lineItem.unitPrice)),
			},
			{
				Header: "",
				id: "goToPartPage",
				Cell: ({ row: { original: lineItem } }: CellProps<CustomerPartOrderLineItem>) => {
					return lineItem.onlinePartId ?
							<Box textAlign="right">
								<Button
									icon="link"
									variant="table"
									onClick={() => onViewPart(lineItem.onlinePartId)}
								>
									View Product
								</Button>
							</Box>
						:	<div />
				},
			},
		],
		[onViewPart]
	)

	return (
		<Modal
			isOpen={isOpen}
			onClose={handleClose}
			title={`Order #${order?.orderId ?? ""}`}
			titleDetail={order?.shipToCustomer.name}
			maxWidth="lg"
			contentMinHeight="20rem"
			leftButtons={
				hasReordered ?
					{
						variant: "text",
						buttonText: "Items added! Go to cart",
						icon: "shopping-cart",
						trailingIcon: "long-arrow-right",
						onClick: () => history.push("/shop/cart-summary"),
					}
				: order && reorderIds.length > 0 ?
					{
						buttonText: "Add these items to cart for reorder",
						icon: "sync",
						variant: "text",
						onClick: onReorder,
						isLoading: isReordering,
					}
				:	undefined
			}
		>
			<GridContainer>
				<GridItem xs={12} sm={6} md={3}>
					<LabeledData label="Order date">
						{order?.orderDate ? formatDate(order.orderDate) : <EmptyValueDash />}
					</LabeledData>
				</GridItem>
				<GridItem xs={12} sm={6} md={3}>
					<LabeledData label="Subtotal (with freight)">
						{order?.subTotal ? formatCurrency(order?.subTotal) : <EmptyValueDash />}
					</LabeledData>
				</GridItem>
				<GridItem xs={12} sm={6} md={3}>
					<LabeledData label="Tax">
						{order?.taxTotal ? formatCurrency(order?.taxTotal) : <EmptyValueDash />}
					</LabeledData>
				</GridItem>
				<GridItem xs={12} sm={6} md={3}>
					<LabeledData label="Order total">
						{order?.total ? formatCurrency(order?.total) : <EmptyValueDash />}
					</LabeledData>
				</GridItem>
				<GridItem xs={12} sm={6} md={3}>
					<LabeledData label="Status">{order?.status?.description}</LabeledData>
				</GridItem>
				<GridItem xs={12} sm={6} md={3}>
					<LabeledData label="Purchase order #">
						{order?.purchaseOrderNumber || <EmptyValueDash />}
					</LabeledData>
				</GridItem>
				<GridItem xs={12} sm={6} md={3}>
					<Label>Shipped to</Label>
					<ParagraphList
						isLoading={orderLoading}
						loadingRowCount={0}
						lines={[
							!order?.alternateAddress ? order?.shipToCustomer.name : undefined,
							order?.alternateAddress?.address1 || order?.shipToCustomer.address1,
							order?.alternateAddress?.address2 || order?.shipToCustomer.address2,
							`${order?.alternateAddress?.city || order?.shipToCustomer.city}, ${
								order?.alternateAddress?.state || order?.shipToCustomer.state
							}`,
							order?.alternateAddress?.postalcode ||
								order?.shipToCustomer.postalcode,
						]}
					/>
				</GridItem>
			</GridContainer>

			<Box mt={2}>
				<Table
					data={order?.lineItems ?? []}
					columns={columns}
					isLoading={orderLoading}
					loadingText="Loading order details..."
				/>
			</Box>
		</Modal>
	)
}
