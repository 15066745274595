import { FC } from "react"

import { useHistory } from "react-router-dom"

import { Box, Button, Callout, Heading, Icon, Paragraph } from "@ncs/web-legos"

import { PageContentWrapper } from "~/shared-components"

export const WelcomeNewAccount: FC = () => {
	const history = useHistory()

	return (
		<PageContentWrapper title="Welcome" breadcrumbs={[{ name: "Welcome" }]}>
			<Callout display="block" variant="info" p={2} m="auto" maxWidth={50} mx="auto">
				<Box display="flex" alignItems="center" columnGap={0.75}>
					<Icon icon="check-circle" family="solid" color="success" fontSize={1.35} />
					<Heading variant="h3" bold>
						Welcome! Your new account has been created.
					</Heading>
				</Box>
				<Paragraph mt={2}>
					If you've done business with NCS before, we'll connect your accounts and load
					in our existing information. This process typically takes a few business days
					to complete.
				</Paragraph>
			</Callout>

			<Box textAlign="center" mt={5}>
				<Button
					variant="secondary-cta"
					onClick={() => history.push("/shop")}
					icon="car-wash"
				>
					Explore the NCS Shop
				</Button>
			</Box>
		</PageContentWrapper>
	)
}
