import React, { Dispatch, SetStateAction, useMemo } from "react"

import {
	CustomerSite,
	makeApiErrorMessage,
	PaymentMethod,
	StripeBankAccountStatus,
	useAuth,
	useIsUser,
	UserId,
	useSites,
	useUserProfile,
} from "@ncs/ncs-api"
import { isCanadianProvince, isUsState, validatePhone } from "@ncs/ts-utils"
import { Button } from "@ncs/web-legos"

import { useShopContext } from "~/contexts"

export interface PlaceOrderButtonProps {
	isLoading?: boolean
	onPlaceOrder: () => Promise<void>
	setErrorMessage: Dispatch<SetStateAction<string | null>>
	shipToSite: CustomerSite | null | undefined
}

export const PlaceOrderButton: React.FC<PlaceOrderButtonProps> = ({
	isLoading,
	onPlaceOrder,
	setErrorMessage,
	shipToSite,
}) => {
	const auth = useAuth()
	const isDb = useIsUser(UserId.DrivenBrands)
	const [profile, profileLoading] = useUserProfile(auth.user?.id)
	const [userSites, userSitesLoading] = useSites()
	const [{ cart, checkout }] = useShopContext()

	const isPunchout = !!auth.punchOutSessionId

	const handleSubmit = async () => {
		try {
			await onPlaceOrder()
		} catch (e) {
			setErrorMessage(makeApiErrorMessage(e))
		}
	}

	const invalidPhone = useMemo(() => {
		// Don't validate phone for Driven orders.
		if (isDb) return false
		// Phone field is required for everyone else.
		if (!checkout.phone) return true
		// If you're address is not in the US, then we won't try to validate the phone.
		if (isUsState(checkout.alternateAddress?.state || shipToSite?.state) === false)
			return false

		return validatePhone(checkout.phone) === false
	}, [isDb, checkout.phone, checkout.alternateAddress?.state, shipToSite?.state])

	const isReadyToSubmit = useMemo(() => {
		if (profileLoading || (profile?.poRequired && !checkout.purchaseOrder)) {
			return false
		}
		if (cart.length === 0) {
			return false
		}
		if (!checkout.shipToSiteId && !checkout.alternateAddress) {
			return false
		}
		if (!checkout.selectedPaymentMethod && !isPunchout) {
			return false
		}
		if (!checkout.shipments || checkout.shipments.some((shipment) => !shipment.service)) {
			return false
		}
		if (
			checkout.selectedPaymentMethod === PaymentMethod.STORED_CARD &&
			!checkout.selectedCard
		) {
			return false
		}
		if (
			checkout.selectedPaymentMethod === PaymentMethod.STORED_BANK_ACCOUNT &&
			(!checkout.selectedBankAccount ||
				checkout.selectedBankAccount.status !== StripeBankAccountStatus.VERIFIED)
		) {
			return false
		}
		if (checkout.shippitLoading) {
			return false
		}
		if (
			isCanadianProvince(checkout.alternateAddress?.state) === true &&
			checkout.selectedPaymentMethod !== PaymentMethod.CUSTOMER_ACCOUNT
		) {
			return false
		}
		if (userSitesLoading || (!!userSites?.length && !checkout.shipToSiteId)) {
			return false
		}
		if (invalidPhone) {
			return false
		}

		return true
	}, [
		cart,
		checkout,
		isPunchout,
		profile?.poRequired,
		profileLoading,
		userSites?.length,
		userSitesLoading,
		invalidPhone,
	])

	return (
		<>
			<Button
				variant="primary-cta"
				onClick={handleSubmit}
				disabled={!isReadyToSubmit}
				isLoading={isLoading}
				fillContainer
			>
				{isPunchout ? "Complete Order" : "Place Order"}
			</Button>
		</>
	)
}
