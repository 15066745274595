import React, { useMemo } from "react"

import { css, Theme } from "@emotion/react"
import { useHistory } from "react-router-dom"

import { formatCurrency } from "@ncs/ts-utils"
import {
	Box,
	Button,
	Divider,
	Heading,
	Icon,
	Modal,
	Paragraph,
	PartImage,
	Price,
} from "@ncs/web-legos"

import { useOrderTotals, useShopContext } from "~/contexts"

import { QuantityControl } from "./QuantityControl"

export interface AddedToCartModalProps {
	isOpen: boolean
	onClose: () => void
	partId?: string
}

export const AddedToCartModal: React.FC<AddedToCartModalProps> = ({ isOpen, onClose, partId }) => {
	const history = useHistory()
	const [{ cart }, shopDispatch] = useShopContext()
	const { totalItemCount, cartPartsSubtotal } = useOrderTotals()

	const cartPart = useMemo(() => {
		return cart.find((c) => c.part.id === partId)
	}, [partId, cart])

	if (!partId || !cartPart) {
		return null
	}

	const { part, quantity } = cartPart

	const onChangeQuantity = (newQuantity: number) => {
		shopDispatch({
			type: "update part cart quantity",
			payload: {
				partId,
				quantity: newQuantity,
			},
		})
	}

	const onRemove = () => {
		shopDispatch({
			type: "remove from cart",
			payload: partId,
		})
	}

	const goToCart = () => {
		onClose()
		history.push("/shop/cart-summary")
	}

	return (
		<Modal
			title="Added To Cart!"
			isOpen={isOpen}
			onClose={onClose}
			maxWidth="md"
			showCloseButton={false}
		>
			<div css={detailRowStyle}>
				<Box display="flex" alignItems="center" className="image">
					<Icon icon="check-circle" family="solid" color="success" fontSize="1.5rem" />
					<PartImage src={part.imageUrl} alt={part.title} ml={1} maxWidth="10rem" />
				</Box>
				<Box className="quantity" display="flex" alignItems="center">
					<QuantityControl
						value={quantity}
						onChange={onChangeQuantity}
						onChooseZero={onRemove}
						useUpdateButton
					/>
				</Box>
				<div className="name-and-price">
					<Heading mb={0.75}>{part.title}</Heading>
					<Price
						price={part.netPrice}
						nonDiscountedPrice={part.price}
						quantity={quantity}
					/>
				</div>
			</div>
			<Divider />
			<Paragraph mt={1.5}>
				Cart subtotal ({totalItemCount} item{totalItemCount === 1 ? "" : "s"}):{" "}
				<strong>{formatCurrency(cartPartsSubtotal)}</strong>
			</Paragraph>
			<div css={buttonsContainerStyle}>
				<Button variant="secondary-cta" onClick={onClose} fillContainer>
					Continue Shopping
				</Button>
				<Button variant="primary-cta" onClick={goToCart} fillContainer>
					Go To Cart
				</Button>
			</div>
		</Modal>
	)
}

const detailRowStyle = (theme: Theme) => css`
	display: inline-grid;
	grid-gap: 2rem;
	margin-bottom: 1rem;
	align-items: center;
	justify-items: flex-start;
	grid-template-columns: repeat(4, auto);
	grid-template-areas: "check-icon image quantity name-and-price";
	.image {
		grid-area: image;
		img {
			max-width: 10rem;
			${theme.breakpoints.down("xs")} {
				max-width: 100%;
			}
		}
	}
	.quantity {
		grid-area: quantity;
	}
	.name-and-price {
		grid-area: name-and-price;
	}
	${theme.breakpoints.down("sm")} {
		grid-template-columns: auto 1fr;
		grid-template-areas: "image name-and-price" "quantity .";
	}
	${theme.breakpoints.down("xs")} {
		grid-template-columns: 1fr;
		grid-template-areas: "image" "name-and-price" "quantity";
	}
`
const buttonsContainerStyle = (theme: Theme) => css`
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: 1rem;
	margin-top: 3rem;
	${theme.breakpoints.down("sm")} {
		grid-template-columns: 1fr;
	}
`
