import React, { useContext, useMemo } from "react"

import { z } from "zod"

import {
	CreditCard,
	CreditCardSchema,
	DefaultAccessorial,
	NonParentCustomerPart,
	NonParentCustomerPartSchema,
	PaymentMethod,
	ShippingService,
	ShippitAccessorial,
	ShippitAccessorialSchema,
	ShippitItemSchema,
	StripeBankAccount,
	StripeBankAccountSchema,
} from "@ncs/ncs-api"
import { addOrPromote, addOrReplace, extractNumber } from "@ncs/ts-utils"
import { GenericAddress, GenericAddressSchema, useLocalStorageReducer } from "@ncs/web-legos"

const CartPartSchema = z.object({
	part: NonParentCustomerPartSchema,
	quantity: z.number(),
})
export type CartPart = z.infer<typeof CartPartSchema>

export const ShipmentSchema = z.object({
	productType: z.string(),
	service: z.nativeEnum(ShippingService).nullable(),
	parts: z.array(ShippitItemSchema),
	cost: z.number().nullable(),
})
export type Shipment = z.infer<typeof ShipmentSchema>

export const ShopContextStateSchema = z.object({
	cart: z.array(CartPartSchema),
	checkout: z.object({
		shipToSiteId: z.string().nullable(),
		selectedPaymentMethod: z.nativeEnum(PaymentMethod).nullable(),
		selectedCard: CreditCardSchema.nullable(),
		selectedBankAccount: StripeBankAccountSchema.nullable(),
		shipments: z.array(ShipmentSchema).nullable(),
		shippitLoading: z.boolean(),
		purchaseOrder: z.string().nullable(),
		comment: z.string().nullable(),
		alternateAddress: GenericAddressSchema.nullable(),
		accessorials: z.array(ShippitAccessorialSchema),
		phone: z.string().nullable(),
	}),
	recentlyViewedPartIds: z.string().array(),
})
export type ShopContextState = z.infer<typeof ShopContextStateSchema>

const defaultShopContextState: ShopContextState = {
	cart: [],
	checkout: {
		shipToSiteId: null,
		selectedPaymentMethod: null,
		selectedCard: null,
		selectedBankAccount: null,
		shipments: null,
		shippitLoading: false,
		purchaseOrder: null,
		comment: null,
		alternateAddress: null,
		accessorials: [],
		phone: null,
	},
	recentlyViewedPartIds: [],
}

type ShopContextAction =
	| {
			type: "add part to cart"
			payload: {
				part: NonParentCustomerPart
				quantity?: number
			}
	  }
	| {
			type: "add parts to cart"
			payload: {
				part: NonParentCustomerPart
				quantity: number
			}[]
	  }
	| {
			type: "update part cart quantity"
			payload: {
				partId: string
				quantity: number
			}
	  }
	| {
			type: "add or update part cart quantity"
			payload: {
				part: NonParentCustomerPart
				quantity: number
			}
	  }
	| {
			type: "update cart parts part data"
			payload: {
				parts: NonParentCustomerPart[]
			}
	  }
	| {
			type: "remove from cart"
			payload: string
	  }
	| {
			type: "empty cart"
	  }
	| {
			type: "select shipping site"
			payload: {
				siteId: string | null
				preserveAlternateAddress?: boolean
			}
	  }
	| {
			type: "select alternate address"
			payload: {
				alternateAddress: GenericAddress | null
				preserveShipToId?: boolean
			}
	  }
	| {
			type: "set order shipments"
			payload: {
				shipments: Shipment[] | null
				hasChemical: boolean
			}
	  }
	| {
			type: "select shipping service for shipment"
			payload: {
				shipmentProductType: string
				service: ShippingService
				cost: number | null // Might be null if Canadian address
			}
	  }
	| {
			type: "toggle accessorial"
			payload: {
				accessorial: ShippitAccessorial
				isChecked: boolean
			}
	  }
	| {
			type: "set accessorials"
			payload: {
				accessorials: ShippitAccessorial[]
				defaults: DefaultAccessorial[]
			}
	  }
	| {
			type: "set checkout payment method"
			payload: PaymentMethod | null
	  }
	| {
			type: "set checkout card"
			payload: CreditCard
	  }
	| {
			type: "set checkout phone"
			payload: string | null
	  }
	| {
			type: "set checkout bank account"
			payload: StripeBankAccount
	  }
	| {
			type: "set purchase order"
			payload: string | null
	  }
	| {
			type: "set checkout comment"
			payload: string | null
	  }
	| {
			type: "add part to recent parts"
			payload: string
	  }
	| {
			type: "replace state"
			payload: ShopContextState
	  }
	| {
			type: "reset state"
	  }
	| {
			type: "reset checkout state"
	  }
	| {
			type: "set shippit loading status"
			payload: boolean
	  }

export type ShopContextDispatch = (action: ShopContextAction) => void

const ShopContext = React.createContext<[ShopContextState, ShopContextDispatch] | undefined>(
	undefined
)

ShopContext.displayName = "ShopContext"

const shopContextReducer = (
	state: ShopContextState,
	action: ShopContextAction
): ShopContextState => {
	switch (action.type) {
		case "add part to cart": {
			const newId = action.payload.part.id
			const newQuantity =
				(state.cart.find((p) => p.part.id === newId)?.quantity ?? 0) +
				(action.payload.quantity ?? 1)

			return {
				...state,
				cart: addOrReplace(
					{ part: action.payload.part, quantity: newQuantity },
					state.cart,
					(cartPart) => cartPart.part.id === newId
				),
			}
		}
		case "add parts to cart": {
			// We want to add in a bunch of new parts, but if any of them are already in the cart
			// then we want to combine the quantities into one cart part.
			const newPartsWithCombinedQuantities = action.payload.map(({ part, quantity }) => {
				const preExistingQuantity =
					state.cart.find((cartPart) => cartPart.part.id === part.id)?.quantity ?? 0

				return {
					part,
					quantity: quantity + preExistingQuantity,
				}
			})

			// We're going to filter out from the current cart the parts that are in
			// the payload. Their quantities are accounted for above.
			const newIds = action.payload.map(({ part }) => part.id)

			return {
				...state,
				cart: [
					...state.cart.filter(({ part }) => !newIds.includes(part.id)),
					...newPartsWithCombinedQuantities,
				],
			}
		}
		case "update part cart quantity": {
			const cartPart = state.cart.find((c) => c.part.id === action.payload.partId)

			// If the part doesn't already exist in the cart, you're doing it wrong.
			if (!cartPart) {
				console.error("Trying to update the cart quantity of a part not in the cart.")

				return state
			}

			return {
				...state,
				cart: addOrReplace(
					{ part: cartPart.part, quantity: action.payload.quantity },
					state.cart,
					(item) => item.part.id === action.payload.partId
				),
			}
		}
		case "add or update part cart quantity": {
			return {
				...state,
				cart: addOrReplace(
					action.payload,
					state.cart,
					(cartPart) =>
						cartPart.part.onlinePartNumber === action.payload.part.onlinePartNumber
				),
			}
		}
		case "update cart parts part data": {
			return {
				...state,
				cart: state.cart.map((cartPart) => {
					const updatedPart = action.payload.parts.find((p) => p.id === cartPart.part.id)

					return updatedPart ?
							{
								...cartPart,
								part: updatedPart,
							}
						:	cartPart
				}),
			}
		}
		case "remove from cart": {
			const updatedCart = [...state.cart.filter(({ part }) => part.id !== action.payload)]

			return {
				...state,
				cart: updatedCart,
				checkout: {
					...state.checkout,
					shipments: updatedCart.length === 0 ? [] : state.checkout.shipments,
				},
			}
		}
		case "empty cart": {
			return {
				...state,
				cart: [],
				checkout: {
					...state.checkout,
					shipments: [],
				},
			}
		}
		case "select shipping site": {
			return {
				...state,
				checkout: {
					...state.checkout,
					alternateAddress:
						action.payload.preserveAlternateAddress ?
							state.checkout.alternateAddress
						:	null,
					shipToSiteId: action.payload.siteId,
				},
			}
		}
		case "select alternate address": {
			return {
				...state,
				checkout: {
					...state.checkout,
					shipToSiteId:
						action.payload.preserveShipToId ? state.checkout.shipToSiteId : null,
					alternateAddress: action.payload.alternateAddress,
				},
			}
		}
		case "set order shipments": {
			// The order shipments are what comes back from Shippit, describing how
			// we're splitting up parts and what the shipping options are for each.
			return {
				...state,
				checkout: {
					...state.checkout,
					shipments: action.payload.shipments,
					shippitLoading: false,
					// If the new shipment doesn't have any chemical parts, then clear out the
					// current accessorials.
					accessorials: action.payload.hasChemical ? state.checkout.accessorials : [],
				},
			}
		}
		case "select shipping service for shipment": {
			return {
				...state,
				checkout: {
					...state.checkout,
					shipments: [
						...(state.checkout.shipments ?? []).map((shipment) =>
							shipment.productType === action.payload.shipmentProductType ?
								{
									...shipment,
									cost: action.payload.cost,
									service: action.payload.service,
								}
							:	shipment
						),
					],
				},
			}
		}
		case "toggle accessorial": {
			return {
				...state,
				checkout: {
					...state.checkout,
					accessorials:
						action.payload.isChecked ?
							[...state.checkout.accessorials, action.payload.accessorial]
						:	[
								...state.checkout.accessorials.filter(
									(a) =>
										a.lineItemTypeId !==
										action.payload.accessorial.lineItemTypeId
								),
							],
				},
			}
		}
		case "set accessorials": {
			// Make an array of defaults to add.
			const defaults = action.payload.defaults.flatMap(
				(defaultAccessorial): ShippitAccessorial[] => {
					const match = action.payload.accessorials.find(
						(a) => a.lineItemTypeId.toString() === defaultAccessorial.lineItemTypeId
					)

					return match ? [match] : []
				}
			)

			// Assemble new list.
			const newAccessorials: ShippitAccessorial[] = [
				...state.checkout.accessorials.flatMap(
					(existingAccessorial): ShippitAccessorial[] => {
						// If we're about to add it from defaults, filter it out.
						if (
							defaults.some(
								(defaultAccessorial) =>
									defaultAccessorial.lineItemTypeId ===
									existingAccessorial.lineItemTypeId
							)
						) {
							return []
						}

						// Otherwise, look for this one in the new accessorials list and return
						// it from there to get its updated price.
						const match = action.payload.accessorials.find((newAccessorial) => {
							return (
								newAccessorial.lineItemTypeId ===
								existingAccessorial.lineItemTypeId
							)
						})
						return match ? [match] : []
					}
				),
				...defaults,
			]

			return {
				...state,
				checkout: {
					...state.checkout,
					accessorials: newAccessorials,
				},
			}
		}
		case "set checkout payment method": {
			return {
				...state,
				checkout: {
					...state.checkout,
					selectedPaymentMethod: action.payload,
					selectedCard: null,
					selectedBankAccount: null,
				},
			}
		}
		case "set checkout card": {
			return {
				...state,
				checkout: {
					...state.checkout,
					selectedCard: action.payload,
				},
			}
		}
		case "set checkout phone": {
			return {
				...state,
				checkout: {
					...state.checkout,
					phone: action.payload,
				},
			}
		}
		case "set checkout bank account": {
			return {
				...state,
				checkout: {
					...state.checkout,
					selectedBankAccount: action.payload,
				},
			}
		}
		case "set purchase order": {
			return {
				...state,
				checkout: {
					...state.checkout,
					purchaseOrder: action.payload,
				},
			}
		}
		case "set checkout comment": {
			return {
				...state,
				checkout: {
					...state.checkout,
					comment: action.payload,
				},
			}
		}
		case "add part to recent parts": {
			return {
				...state,
				recentlyViewedPartIds: addOrPromote(
					action.payload,
					state.recentlyViewedPartIds
				).slice(0, 10), // We'll keep the last 10 in there at a time.
			}
		}
		case "reset checkout state": {
			return {
				...state,
				checkout: defaultShopContextState.checkout,
				cart: defaultShopContextState.cart,
			}
		}
		case "reset state": {
			return defaultShopContextState
		}
		case "replace state": {
			return action.payload
		}
		case "set shippit loading status": {
			return {
				...state,
				checkout: {
					...state.checkout,
					shippitLoading: action.payload,
				},
			}
		}
	}
}

const onAuthChange = (state: ShopContextState, dispatch: ShopContextDispatch) => {
	dispatch({
		type: "replace state",
		payload: state,
	})
}

export const ShopContextProvider: React.FC = ({ children }) => {
	const shopContext = useLocalStorageReducer(shopContextReducer, defaultShopContextState, {
		keyOverride: "shop-context",
		onAuthChange,
		zodSchema: ShopContextStateSchema,
	})

	return <ShopContext.Provider value={shopContext}>{children}</ShopContext.Provider>
}

export const useShopContext = (): [ShopContextState, ShopContextDispatch] => {
	const context = useContext(ShopContext)

	if (context === undefined) {
		throw new Error("useShopContext must be used within ShopContextProvider")
	}

	return context
}

/**
 * Sugar for getting all the derived counts from the current state of the cart.
 */
export const useOrderTotals = (): {
	totalItemCount: number
	shipmentPartsSubtotal: number
	chemicalShipmentPartsSubtotal: number
	cartPartsSubtotal: number
	taxesTotal: number
	shippingTotalWithoutAccessorials: number
	accessorialsTotal: number
	shippingTotal: number
	orderGrandTotal: number
} => {
	const [{ cart, checkout }] = useShopContext()

	const totalItemCount = useMemo(() => {
		return cart.reduce((prev, { quantity }) => prev + quantity, 0)
	}, [cart])

	const cartPartsSubtotal = useMemo(() => {
		// Tally up the price subtotal based on the parts currently in the cart.
		const subtotal = cart.reduce(
			(total, { part, quantity }) => total + part.netPrice * quantity,
			0
		)

		return extractNumber(subtotal.toFixed(2))
	}, [cart])

	const shipmentPartsSubtotal = useMemo(() => {
		// Tally up the price subtotal based on the shipments in the checkout state,
		// from the `/summary` endpoint. Falls back to what's in the cart if there
		// aren't any shipments yet.

		// Note a potential gotcha here: Once shipments are received, this hook will
		// keep returning them, even if the cart changes after that. So unless your
		// component is also calling for updated shipments when that happens, you
		// probably want to use `cartPartsSubtotal` instead, which just always looks
		// directly at the cart.
		if (checkout.shipments && checkout.shipments.length > 0) {
			const total = checkout.shipments.reduce(
				(runningTotal, shipment) =>
					runningTotal +
					shipment.parts.reduce(
						(partsTotal: number, part) => partsTotal + part.price * part.quantity,
						0
					),
				0
			)

			return extractNumber(total.toFixed(2))
		} else {
			return cartPartsSubtotal
		}
	}, [checkout, cartPartsSubtotal])

	// Add up the taxes in the shipments.
	const taxesTotal = useMemo(() => {
		if (checkout.shipments && checkout.shipments.length > 0) {
			const total = checkout.shipments.reduce(
				(shipmentTotal: number, shipment) =>
					shipmentTotal +
					shipment.parts.reduce(
						(partTotal: number, shipmentPart) =>
							partTotal +
							shipmentPart.price *
								((shipmentPart.taxRate ?? 0) / 100) *
								shipmentPart.quantity,
						0
					),
				0
			)

			return extractNumber(total.toFixed(2))
		}

		// If shipments haven't been received yet from the server, then we'll provide an estimate at 7%.
		const estTotal = cart.reduce(
			(runningTotal, cartPart) =>
				runningTotal + cartPart.part.netPrice * 0.07 * cartPart.quantity,
			0
		)

		return extractNumber(estTotal.toFixed(2))
	}, [cart, checkout])

	// Add up the prices for all items in the chemicals shipment.
	const chemicalShipmentPartsSubtotal = useMemo((): number => {
		const total =
			checkout.shipments?.reduce((shipmentsTotal, shipment) => {
				if (shipment.productType === "chemicals") {
					return (
						shipmentsTotal +
						shipment.parts.reduce(
							(partsTotal, part) => partsTotal + part.price * part.quantity,
							0
						)
					)
				}

				return shipmentsTotal
			}, 0) ?? 0

		return extractNumber(total.toFixed(2))
	}, [checkout.shipments])

	const shippingTotalWithoutAccessorials = useMemo(() => {
		const total = (checkout.shipments ?? []).reduce(
			(prev, shipment) => prev + (shipment.cost ?? 0),
			0
		)

		return extractNumber(total.toFixed(2))
	}, [checkout.shipments])

	const accessorialsTotal = useMemo(() => {
		return extractNumber(
			checkout.accessorials
				.reduce((prev, accessorial) => prev + accessorial.rate, 0)
				.toFixed(2)
		)
	}, [checkout.accessorials])

	// The actual freight charges and the accessorials are ultimately different
	// things when we post the order to the backend, but for the purposes of
	// displaying the total freight charges to the user, we'll add them all
	// together.
	const shippingTotal = useMemo(() => {
		return shippingTotalWithoutAccessorials + accessorialsTotal
	}, [shippingTotalWithoutAccessorials, accessorialsTotal])

	const orderGrandTotal = useMemo(() => {
		return shipmentPartsSubtotal + taxesTotal + shippingTotal
	}, [shipmentPartsSubtotal, taxesTotal, shippingTotal])

	return {
		totalItemCount,
		shipmentPartsSubtotal,
		chemicalShipmentPartsSubtotal,
		cartPartsSubtotal,
		taxesTotal,
		shippingTotalWithoutAccessorials,
		accessorialsTotal,
		shippingTotal,
		orderGrandTotal,
	}
}
