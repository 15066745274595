import { FC, useMemo } from "react"

import { css, useTheme } from "@emotion/react"
import { Link as RouterLink } from "react-router-dom"

import { withCssUnit } from "@ncs/ts-utils"

import { Icon, IconFamily, IconName } from "./Icon"

export interface LinkProps {
	to: string
	external?: boolean
	newTab?: boolean
	mailTo?: boolean
	bold?: boolean
	underline?: boolean
	icon?: IconName
	iconFamily?: IconFamily
	/** @default "trailing" */
	iconPosition?: "leading" | "trailing"
	inline?: boolean
	fontSize?: string | number
	className?: string
}

export const Link: FC<LinkProps> = ({
	to,
	external,
	newTab,
	mailTo,
	underline,
	bold = true,
	icon,
	iconFamily,
	iconPosition = "trailing",
	inline = true,
	fontSize,
	className,
	children,
}) => {
	const theme = useTheme()

	const addScheme = (href: string) => {
		if (mailTo) {
			if (href.trim().startsWith("mailto:")) {
				return href
			} else {
				return `mailto:${href}`
			}
		} else if (href.trim().startsWith("http")) {
			return href
		} else {
			return `https://${href.trim()}`
		}
	}

	const linkContainerStyle = useMemo(() => {
		return css`
			position: relative;
			display: ${inline ? "inline-flex" : "flex"};
			align-items: center;
			a {
				color: ${theme.palette.primary.main};
				text-decoration: ${underline === true ? "underline"
				: underline === false ? "none"
				: theme.defaultLinkTextDecoration ?? "none"};
				&:hover,
				&:focus {
					color: ${theme.palette.primary.light};
				}
				font-size: ${fontSize ? withCssUnit(fontSize) : undefined};
				transition: color 250ms ease-out;
			}
			svg {
				position: relative;
				margin-right: ${iconPosition === "leading" ? "0.35rem" : undefined};
				margin-left: ${iconPosition === "trailing" ? "0.35rem" : undefined};
			}
		`
	}, [
		theme.palette.primary.main,
		theme.palette.primary.light,
		theme.defaultLinkTextDecoration,
		iconPosition,
		underline,
		inline,
		fontSize,
	])

	const inner = (
		<>
			{iconPosition === "leading" && !!icon && (
				<Icon icon={icon} family={iconFamily} fontSize={fontSize} />
			)}
			{bold ?
				<strong>{children}</strong>
			:	children}
			{iconPosition === "trailing" && !!icon && (
				<Icon icon={icon} family={iconFamily} fontSize={fontSize} />
			)}
		</>
	)

	return (
		<span css={linkContainerStyle} className={className}>
			{external || mailTo ?
				<a // eslint-disable-line
					href={addScheme(to)}
					target={newTab || mailTo ? "_blank" : undefined}
					rel={newTab || mailTo ? "noreferrer noopener" : undefined}
				>
					{inner}
				</a>
			:	<RouterLink
					target={newTab ? "_blank" : undefined}
					rel={newTab ? "noreferrer noopener" : undefined}
					to={to}
				>
					{inner}
				</RouterLink>
			}
		</span>
	)
}
