import React, { memo } from "react"

import { css, Theme } from "@emotion/react"
import { Link } from "react-router-dom"

import { useAuth } from "@ncs/ncs-api"
import { Box, Icon, Paragraph } from "@ncs/web-legos"

import { ContentWrapper } from "./ContentWrapper"
import { Logo } from "./Logo"
import { NcsSocial } from "./NcsSocial"
import { SiteNavigation } from "./SiteNavigation"

export const SiteFooter: React.FC = memo(() => {
	const { user } = useAuth()

	return (
		<footer css={footerStyle}>
			<ContentWrapper>
				<div css={innerFooterStyle}>
					{user ?
						<SiteNavigation location="footer" />
					:	<div />}

					<div>
						<Paragraph>1500 SE 37th St.</Paragraph>
						<Paragraph>Grimes, IA 50111</Paragraph>
						<Paragraph>1-833-NCS-Wash</Paragraph>
						<Box mt={2}>
							<NcsSocial textColor="light" />
						</Box>
					</div>

					<Paragraph maxWidth="15rem">
						Copyright &copy; {new Date().getFullYear()} National Carwash Solutions. All
						rights reserved.
					</Paragraph>

					<div>
						<Link to="/">
							<Logo company="ncsWhite" width="175px" />
						</Link>
						<Box display="flex" justifyContent="center" mt={2}>
							<Icon icon="user-headset" color="white" />
							<Paragraph ml={0.75}>1-833-NCS-WASH</Paragraph>
						</Box>
					</div>
				</div>
			</ContentWrapper>
		</footer>
	)
})

const footerStyle = (theme: Theme) => css`
	background: ${theme.palette.primary.dark};
	margin-top: 15rem;
`
const innerFooterStyle = (theme: Theme) => css`
	display: grid;
	grid-template-columns: 1fr 1fr 1fr auto;
	padding: 4rem 3rem 6rem 3rem;
	gap: 3rem;
	${theme.breakpoints.down("sm")} {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		text-align: center;
	}
	p,
	a {
		color: white;
		font-size: 0.85rem;
	}
	a {
		text-decoration: none;
		font-weight: normal;
		&:hover {
			opacity: 0.75;
		}
	}
	button {
		color: white;
	}
`
