import { CamelToSnakeCase } from "@ncs/ts-utils"

import {
	ApiGetQuery,
	ApiGetQueryOptions,
	ApiInfiniteGetQuery,
	ApiInfiniteGetQueryOptions,
	ApiPatchMutation,
	ApiPostMutation,
	useGetRequest,
	useInfiniteGetRequest,
	usePatchRequest,
	usePostRequest,
} from "../../request-hooks"
import { WorkordersUrlPath } from "../workorders"
import {
	CreateDispatchPost,
	CreateDispatchResponse,
	CustomerDispatch,
	CustomerDispatchCallLog,
	CustomerDispatchDocument,
	CustomerDispatchPatch,
	CustomerDispatchPost,
	CustomerDispatchQueryParams,
	CustomerWorkOrder,
	Dispatch,
	DispatchCallLog,
	DispatchQueryParams,
	DispatchUrlPaths,
	UpdateDispatchPatch,
	WorkOrder,
} from "./types"

export const useCustomerDispatch = (
	dispatchId: string | null,
	options?: ApiGetQueryOptions<CustomerDispatch>
): ApiGetQuery<CustomerDispatch> => {
	const [dispatch, loading, extras] = useGetRequest<CustomerDispatch>(
		[DispatchUrlPaths.Dispatch, DispatchUrlPaths.CustomerView, dispatchId ?? ""],
		{
			...options,
			queryConfig: {
				enabled: !!dispatchId,
				...options?.queryConfig,
			},
		}
	)

	return [dispatch, loading, extras]
}

export const useCustomerDispatches = (
	options?: ApiInfiniteGetQueryOptions<CustomerDispatch, CustomerDispatchQueryParams>
): ApiInfiniteGetQuery<CustomerDispatch> => {
	return useInfiniteGetRequest<CustomerDispatch>(
		[DispatchUrlPaths.Dispatch, DispatchUrlPaths.CustomerView],
		{
			...options,
		}
	)
}

export const useCreateCustomerDispatch = (): ApiPostMutation<CustomerDispatchPost> => {
	return usePostRequest<CustomerDispatchPost>(
		[DispatchUrlPaths.Dispatch, DispatchUrlPaths.CustomerView],
		{
			keyToInvalidate: DispatchUrlPaths.Dispatch,
		}
	)
}

export const useCustomerDispatchWorkOrders = (
	dispatchId: string | null
): ApiGetQuery<CustomerWorkOrder[]> => {
	return useGetRequest<CustomerWorkOrder[]>(
		[
			DispatchUrlPaths.Dispatch,
			DispatchUrlPaths.CustomerView,
			dispatchId ?? "",
			DispatchUrlPaths.WorkOrders,
		],
		{
			queryConfig: {
				enabled: !!dispatchId,
			},
		}
	)
}

export const useCustomerDispatchDocument = (
	dispatchId: string | null
): ApiGetQuery<CustomerDispatchDocument> => {
	return useGetRequest<CustomerDispatchDocument>(
		[
			DispatchUrlPaths.Dispatch,
			DispatchUrlPaths.CustomerView,
			dispatchId ?? "",
			DispatchUrlPaths.Documents,
		],
		{
			queryConfig: {
				enabled: !!dispatchId,
			},
		}
	)
}

export const useCustomerDispatchCallLogs = (
	dispatchId: string | null
): ApiGetQuery<CustomerDispatchCallLog[]> => {
	return useGetRequest<CustomerDispatchCallLog[]>(
		[
			DispatchUrlPaths.Dispatch,
			DispatchUrlPaths.CustomerView,
			dispatchId ?? "",
			DispatchUrlPaths.CallLogs,
		],
		{
			queryConfig: {
				enabled: !!dispatchId,
			},
		}
	)
}

export const useUpdateCustomerDispatch = (): ApiPatchMutation<CustomerDispatchPatch> => {
	return usePatchRequest<CustomerDispatchPatch>(
		[DispatchUrlPaths.Dispatch, DispatchUrlPaths.CustomerView],
		{
			keyToInvalidate: [DispatchUrlPaths.Dispatch, DispatchUrlPaths.CustomerView],
		}
	)
}

export const useDispatches = (
	options?: ApiInfiniteGetQueryOptions<Dispatch, DispatchQueryParams>,
	fields?: (CamelToSnakeCase<keyof Dispatch> | "_id")[]
): ApiInfiniteGetQuery<Dispatch> => {
	const defaultFields: (CamelToSnakeCase<keyof Dispatch> | "_id")[] = [
		"_id",
		"assigned_employee",
		"bill_not_to_exceed",
		"bill_to_customer",
		"call_back_number",
		"call_received_date",
		"call_type",
		"caller_name",
		"closed_date",
		"created_by",
		"created_date",
		"customer",
		"dispatch_contact_exists",
		"dispatch_number",
		"erp",
		"invoice",
		"po_ticket",
		"priority",
		"status",
		"symptoms",
		"nuisance",
	]

	const params = {
		...options?.params,
		fields: (fields || defaultFields).join(","),
	}

	return useInfiniteGetRequest([DispatchUrlPaths.Dispatches], {
		...options,
		params,
	})
}

export const useCreateDispatch = (): ApiPostMutation<
	CreateDispatchPost,
	CreateDispatchResponse
> => {
	return usePostRequest([DispatchUrlPaths.Api, DispatchUrlPaths.CreateDispatch], {
		keyToInvalidate: [
			[DispatchUrlPaths.Dispatch],
			[DispatchUrlPaths.Dispatches],
			[WorkordersUrlPath.Workorders],
		],
	})
}

export const useDispatch = (
	dispatchId: string | null | undefined,
	options?: ApiGetQueryOptions<Dispatch>
): ApiGetQuery<Dispatch> => {
	return useGetRequest([DispatchUrlPaths.Dispatches, dispatchId ?? ""], {
		...options,
		queryConfig: {
			enabled: !!dispatchId,
			...options?.queryConfig,
		},
	})
}

export const useUpdateDispatch = (): ApiPatchMutation<UpdateDispatchPatch> => {
	return usePatchRequest([DispatchUrlPaths.Dispatches], {
		keyToInvalidate: DispatchUrlPaths.Dispatches,
	})
}

export const useDispatchCallLogs = (
	dispatchId: string | null | undefined
): ApiGetQuery<DispatchCallLog[]> => {
	return useGetRequest(
		[DispatchUrlPaths.Dispatches, dispatchId ?? "", DispatchUrlPaths.CallLogs],
		{
			queryConfig: {
				enabled: !!dispatchId,
			},
		}
	)
}

export const useDispatchWorkorders = (
	dispatchId: string | null | undefined
): ApiGetQuery<WorkOrder[]> => {
	return useGetRequest(
		[DispatchUrlPaths.Dispatches, dispatchId ?? "", DispatchUrlPaths.WorkOrders],
		{
			queryConfig: {
				enabled: !!dispatchId,
			},
		}
	)
}
