import React from "react"

import { css, Theme } from "@emotion/react"

import { Box, BoxProps } from "../layout"

export interface ErrorTextProps extends BoxProps {
	className?: string
}

export const ErrorText: React.FC<ErrorTextProps> = ({ className, children, ...rest }) => {
	return (
		<Box {...rest}>
			<span className={className} css={[style]}>
				{children}
			</span>
		</Box>
	)
}

const style = (theme: Theme) => css`
	color: ${theme.palette.error.main};
	font-size: 0.75rem;
	font-weight: bold;
`
