import { FC, memo, useState } from "react"

import { css, Theme } from "@emotion/react"
import { Link } from "react-router-dom"

import { useAuth } from "@ncs/ncs-api"
import { Box, Button, useScreenSizeMatch } from "@ncs/web-legos"

import { ContentWrapper } from "./ContentWrapper"
import { Logo } from "./Logo"
import { MobileNavModal } from "./MobileNavModal"
import { ShopBar } from "./shop-bar"
import { SiteNavigation } from "./SiteNavigation"

export const SiteHeader: FC = memo(() => {
	const screenIsTiny = useScreenSizeMatch("xs")
	const screenIsSmall = useScreenSizeMatch("sm")
	const [showNavModal, setShowNavModal] = useState(false)
	const { user } = useAuth()

	return (
		<>
			<header css={headerStyle}>
				<ContentWrapper>
					<Box
						display="flex"
						justifyContent={user ? "space-between" : "center"}
						alignItems="center"
						flexDirection={screenIsTiny ? "column" : "row"}
					>
						<Link to="/">
							<Logo maxWidth={screenIsTiny ? "7rem" : undefined} width="175px" />
						</Link>

						{!!user &&
							(screenIsSmall ?
								<Button
									variant="secondary-cta"
									icon="bars"
									onClick={() => setShowNavModal(true)}
									containerProps={screenIsTiny ? { my: "1rem" } : undefined}
								>
									MENU
								</Button>
							:	<SiteNavigation css={navStyle} />)}
					</Box>
				</ContentWrapper>

				<ShopBar />
			</header>

			<MobileNavModal isOpen={showNavModal} onClose={() => setShowNavModal(false)} />
		</>
	)
})

const headerStyle = css`
	padding: 1rem 0;
`
const navStyle = (theme: Theme) => css`
	display: flex;
	align-items: center;
	justify-content: "space-around";
	ul {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		li {
			margin-left: 1.5rem;
			text-align: center;
			${theme.breakpoints.down("md")} {
				margin-left: 0.5rem;
			}
			a {
				display: inline-block;
				text-align: center;
				font-weight: normal;
				text-decoration: none;
				text-transform: uppercase;
				padding: 1rem;
				&.active {
					font-weight: bold;
				}
				${theme.breakpoints.down("sm")} {
					padding: 0.5rem;
				}
			}
			&:first-of-type {
				margin-left: 0;
				a {
					padding-left: 0;
				}
			}
			&:last-child {
				a {
					padding-right: 0;
				}
			}
		}
	}
`
