import React, { useEffect, useMemo, useState } from "react"

import { css, Theme } from "@emotion/react"
import { useHistory } from "react-router-dom"

import {
	CustomerCartSite,
	isNonParentCustomerPart,
	NonParentCustomerPart,
	useCustomerQuickOrder,
	useCustomerQuickOrderSites,
	useIsUser,
	UserId,
} from "@ncs/ncs-api"
import { addOrReplace } from "@ncs/ts-utils"
import {
	AnimatedEntrance,
	Box,
	Button,
	Callout,
	Divider,
	Icon,
	Link,
	LoadingSpinner,
	Paragraph,
	PartImage,
	Price,
	SearchableSelect,
	useScreenSizeMatch,
} from "@ncs/web-legos"

import { CartPart, useShopContext } from "~/contexts"
import { PageContentWrapper, QuantityControl } from "~/shared-components"

export const QuickOrder: React.FC = () => {
	const history = useHistory()
	const screenIsTiny = useScreenSizeMatch("xs")
	const [quickOrderList, quickOrderListLoading] = useCustomerQuickOrderSites()
	const [selectedOrder, setSelectedOrder] = useState<CustomerCartSite | null>(null)
	const [quickParts, quickPartsAreLoading] = useCustomerQuickOrder(
		selectedOrder?.customerId.toString()
	)
	const [, shopDispatch] = useShopContext()
	const [ordersCartState, setOrdersCartState] = useState<{
		[orderId: string]: CartPart[] | undefined
	}>({})
	const [showOrderAddedConfirmation, setShowOrderAddedConfirmation] = useState(false)
	const isDb = useIsUser(UserId.DrivenBrands)

	const onOrderChange = (order: CustomerCartSite | null) => {
		setSelectedOrder(order)
		setShowOrderAddedConfirmation(false)
	}

	useEffect(() => {
		if (selectedOrder?.customerId && quickParts) {
			setOrdersCartState((prev) => ({
				...prev,
				[selectedOrder.customerId.toString()]: quickParts.flatMap((quickPart) => {
					if (!isNonParentCustomerPart(quickPart.onlinePart)) {
						return []
					} else {
						return [
							{
								part: quickPart.onlinePart,
								quantity: quickPart.defaultQuantity ?? 0,
							},
						]
					}
				}),
			}))
		}
	}, [selectedOrder?.customerId, quickParts])

	// If it's driven brands, all the sites have the same order so we'll default
	// select the first in the list.
	useEffect(() => {
		if (
			isDb &&
			!selectedOrder?.customerId &&
			!quickOrderListLoading &&
			quickOrderList &&
			quickOrderList.length > 0 &&
			!showOrderAddedConfirmation
		) {
			setSelectedOrder(quickOrderList[0])
		}
	}, [
		isDb,
		selectedOrder?.customerId,
		quickOrderList,
		quickOrderListLoading,
		showOrderAddedConfirmation,
	])

	const onQuantityChange = (quantity: number, part: NonParentCustomerPart) => {
		if (selectedOrder && ordersCartState[selectedOrder.customerId.toString()]) {
			setOrdersCartState((prev) => {
				const prevOrderState = prev[selectedOrder.customerId.toString()]

				// Note that prevOrderState should always exist, this is just being cautious.
				return prevOrderState ?
						{
							...prev,
							[selectedOrder.customerId.toString()]: addOrReplace(
								{ part, quantity },
								prevOrderState,
								(cartPart) => cartPart.part.id === part.id
							),
						}
					:	prev
			})
		}
	}

	const addToCart = () => {
		if (selectedOrder) {
			setShowOrderAddedConfirmation(true)
			setSelectedOrder(null)
			shopDispatch({
				type: "add parts to cart",
				payload: (ordersCartState[selectedOrder.customerId.toString()] ?? []).filter(
					({ quantity }) => quantity > 0
				),
			})
		}
	}

	const addToCartAndCheckout = () => {
		addToCart()
		history.push("/shop/cart-summary")
	}

	const currentOrderItemCount = useMemo(() => {
		const order =
			selectedOrder?.customerId ?
				ordersCartState[selectedOrder.customerId.toString()]
			:	undefined
		if (!order) return 0

		return order.reduce((tally, { quantity }) => tally + quantity, 0)
	}, [ordersCartState, selectedOrder?.customerId])

	return (
		<PageContentWrapper
			heading="Quick Order"
			breadcrumbs={[
				{
					name: "Shop",
					to: "/shop",
				},
				{
					name: "Quick Order",
				},
			]}
		>
			{quickOrderListLoading && <LoadingSpinner />}

			{!!quickOrderList && quickOrderList.length === 0 && !quickOrderListLoading && (
				<Paragraph color="secondary">
					None of your sites are set up for Quick Order.
				</Paragraph>
			)}

			{!!quickOrderList && quickOrderList.length > 0 && !isDb && (
				<>
					<Paragraph mb={1}>
						Choose one of the Quick Order part lists that have been set up for your
						account.
					</Paragraph>

					<SearchableSelect
						options={quickOrderList ?? []}
						value={selectedOrder}
						getOptionSelected={(option) =>
							option.customerId === selectedOrder?.customerId
						}
						onItemSelect={(option) => onOrderChange(option)}
						label="Your quick orders"
						placeholder="Search..."
						getOptionLabel={(option) =>
							`${option.name} - ${option.city}, ${option.state}`
						}
						isLoading={quickOrderListLoading}
						mb={2}
						maxWidth={40}
					/>

					<Divider mb={1} />
				</>
			)}

			{quickPartsAreLoading && <LoadingSpinner mt={3} text="Loading order parts..." />}

			{!quickPartsAreLoading &&
				!showOrderAddedConfirmation &&
				!!selectedOrder &&
				(ordersCartState[selectedOrder.customerId.toString()] ?? []).length === 0 && (
					<Paragraph small color="secondary">
						No items in this Quick Order.
					</Paragraph>
				)}

			{!quickPartsAreLoading &&
				!showOrderAddedConfirmation &&
				!!selectedOrder &&
				(ordersCartState[selectedOrder.customerId.toString()] ?? []).map(
					({ part, quantity }, i) => (
						<div key={`${i}-${part.onlinePartNumber}`} css={quickOrderListStyle}>
							<div css={partNumberColumn}>{part.onlinePartNumber}</div>
							<div css={partImageColumn}>
								<PartImage src={part.imageUrl} maxWidth={10} />
							</div>
							<div css={partDescriptionColumn}>
								<div>
									{part.title}
									<Paragraph color="secondary" small ellipsisOverflow>
										{part.description}
									</Paragraph>
								</div>
							</div>
							<div css={quantityColumn}>
								<QuantityControl
									value={quantity}
									onChange={(newQuantity) => onQuantityChange(newQuantity, part)}
									onChooseZero={() => onQuantityChange(0, part)}
									zeroQuantityText="0"
									labelPosition="left"
									useUpdateButton
								/>
							</div>
							<div css={priceColumn}>
								<Price small price={part.netPrice} textAlign="right" />
							</div>
						</div>
					)
				)}

			<AnimatedEntrance show={showOrderAddedConfirmation}>
				<Box mt={2}>
					<Callout mb={2}>
						<Paragraph>
							<Icon
								icon="check-circle"
								color="success"
								family="solid"
								css={orderAddedCheckStyle}
							/>
							Items added to cart.
						</Paragraph>
					</Callout>
					<Paragraph maxWidth={50}>
						{isDb ?
							<>
								You can{" "}
								<button
									css={nestedButtonStyle}
									onClick={() => setShowOrderAddedConfirmation(false)}
								>
									add additional Quick Order parts
								</button>
								, or use the search above to browse your entire catalog, or{" "}
								<Link to="/shop/cart-summary">go to your cart</Link> to checkout.
							</>
						:	<>
								Choose another Quick Order from the list above, or{" "}
								<Link to="/shop/cart-summary">go to your cart</Link> to checkout.
							</>
						}
					</Paragraph>
				</Box>
			</AnimatedEntrance>

			{!quickPartsAreLoading && !!selectedOrder && !showOrderAddedConfirmation && (
				<>
					<Paragraph textAlign="right" mt={3} mb={1}>
						<strong>{currentOrderItemCount} items</strong> to be added
					</Paragraph>
					<Box
						display="flex"
						justifyContent="flex-end"
						flexWrap="wrap"
						alignItems="flex-start"
					>
						<Button
							variant="secondary-cta"
							onClick={addToCart}
							containerProps={{ mb: 1 }}
							fillContainer={screenIsTiny}
							disabled={currentOrderItemCount === 0}
						>
							Add Items To Cart
						</Button>
						<Button
							variant="primary-cta"
							icon="long-arrow-right"
							containerProps={{ ml: screenIsTiny ? undefined : 2 }}
							onClick={addToCartAndCheckout}
							fillContainer={screenIsTiny}
							disabled={currentOrderItemCount === 0}
						>
							Add Items + Checkout
						</Button>
					</Box>
				</>
			)}
		</PageContentWrapper>
	)
}

const quickOrderListStyle = (theme: Theme) => css`
	display: grid;
	grid-template-columns: 6rem 7rem minmax(0, 1fr) 13rem 7rem;
	align-items: center;
	grid-template-areas: "partNumberColumn partImageColumn partDescriptionColumn quantityColumn priceColumn";
	border-bottom: 1px solid #eee;
	margin-bottom: 1rem;
	padding-bottom: 1rem;
	${theme.breakpoints.down("xs")} {
		grid-template-columns: 1fr 1fr 1fr;
		grid-template-areas:
			"partImageColumn partImageColumn partImageColumn"
			"partNumberColumn . ."
			"partDescriptionColumn partDescriptionColumn partDescriptionColumn"
			"quantityColumn priceColumn .";
		margin-bottom: 2rem;
	}
`
const partNumberColumn = css`
	grid-area: partNumberColumn;
`
const partImageColumn = css`
	grid-area: partImageColumn;
	padding: 0 1rem;
`
const partDescriptionColumn = css`
	grid-area: partDescriptionColumn;
`
const quantityColumn = (theme: Theme) => css`
	grid-area: quantityColumn;
	text-align: right;
	${theme.breakpoints.down("xs")} {
		text-align: left;
	}
`
const priceColumn = css`
	grid-area: priceColumn;
	padding-left: 1rem;
	text-align: right;
`
const orderAddedCheckStyle = css`
	margin-right: 0.5rem;
`
const nestedButtonStyle = ({ palette }: Theme) => css`
	color: ${palette.primary.main};
	font-weight: bold;
	text-decoration: underline;
	background: none;
	border: 0;
	padding: 0;
	&:hover {
		color: ${palette.primary.light};
	}
`
