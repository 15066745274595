import React, { useState } from "react"

import { captureException } from "@sentry/minimal"
import { useHistory } from "react-router-dom"

import { makeApiErrorMessage, useAuth, useSendPasswordReset } from "@ncs/ncs-api"
import {
	AnimatedEntrance,
	Box,
	Button,
	ErrorText,
	Icon,
	Paragraph,
	TextInput,
} from "@ncs/web-legos"

import { PageContentWrapper } from "~/shared-components"

export const ForgotPassword: React.FC = () => {
	const auth = useAuth()
	const history = useHistory()
	const [emailAddress, setEmailAddress] = useState<string | null>(null)
	const [emailSent, setEmailSent] = useState(false)
	const [loading, setLoading] = useState(false)
	const [errorMessage, setErrorMessage] = useState<string | null>(null)
	const sendReset = useSendPasswordReset()

	const onReset = async () => {
		const email = auth?.user?.email ?? emailAddress

		if (email) {
			try {
				setLoading(true)
				setErrorMessage(null)
				await sendReset({ email })
				setEmailSent(true)
			} catch (e) {
				setErrorMessage(makeApiErrorMessage(e))
				captureException(e)
			} finally {
				setLoading(false)
			}
		} else {
			setErrorMessage("Please enter your email address")
		}
	}

	return (
		<PageContentWrapper title="Forgot Password">
			<AnimatedEntrance show>
				<Box mt={5}>
					{!emailSent ?
						<>
							<Button
								icon="long-arrow-left"
								containerProps={{ mb: 4 }}
								onClick={() => history.goBack()}
							>
								Back
							</Button>
							<Paragraph mb={2}>
								If you need to change your password, we can send you an email with
								instructions to reset it.
							</Paragraph>

							{!auth?.user?.email && (
								<Box mb={2}>
									<Paragraph mb={0.5}>
										Enter the email that you used to register.
									</Paragraph>
									<TextInput
										value={emailAddress}
										onChange={setEmailAddress}
										placeholder="Your account email..."
										maxWidth={20}
									/>
								</Box>
							)}

							<Button variant="secondary-cta" onClick={onReset} isLoading={loading}>
								Send Reset Email
							</Button>
						</>
					:	<AnimatedEntrance show display="flex" columnGap={1} alignItems="center">
							<Icon icon="envelope" fontSize="2rem" />
							<Paragraph>
								Please check your email in a minute or so for further instructions.
							</Paragraph>
						</AnimatedEntrance>
					}

					{!!errorMessage && <ErrorText mt={1}>{errorMessage}</ErrorText>}
				</Box>
			</AnimatedEntrance>
		</PageContentWrapper>
	)
}
