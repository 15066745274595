import { css } from "@emotion/react"

import AvenirNextBoldWoff from "~/fonts/AvenirNextLTPro-Bold.woff"
import AvenirNextBoldWoff2 from "~/fonts/AvenirNextLTPro-Bold.woff2"
import AvenirNextRegularWoff from "~/fonts/AvenirNextLTPro-Regular.woff"
import AvenirNextRegularWoff2 from "~/fonts/AvenirNextLTPro-Regular.woff2"

export const fonts = css`
	@font-face {
		font-family: "Avenir Next";
		font-style: normal;
		font-weight: 400;
		src:
			url(${AvenirNextRegularWoff2}) format("woff2"),
			url(${AvenirNextRegularWoff}) format("woff");
	}
	@font-face {
		font-family: "Avenir Next";
		font-style: bold;
		font-weight: 800;
		src:
			url(${AvenirNextBoldWoff2}) format("woff2"),
			url(${AvenirNextBoldWoff}) format("woff");
	}
`
