import React, { useEffect, useMemo, useState } from "react"

import { useAuth, useIsUser, UserId, useSites } from "@ncs/ncs-api"
import {
	AnimatedEntrance,
	Box,
	Button,
	GenericAddress,
	getAddressFields,
	getGenericAddress,
	Paragraph,
	ParagraphList,
	PhoneInput,
	SiteSelector,
} from "@ncs/web-legos"

import { useShopContext } from "~/contexts"

import { SelectAddressModal } from "./SelectAddressModal"

export const ChooseShippingAddress: React.FC = () => {
	const [sites, sitesLoading] = useSites()
	const { punchOutSessionId } = useAuth()
	const isDb = useIsUser(UserId.DrivenBrands)
	const [{ checkout }, shopDispatch] = useShopContext()
	const [showSelectModal, setShowSelectModal] = useState(false)

	useEffect(() => {
		// If the sites list is only one site long, go ahead and select it automatically.
		if (!sitesLoading && sites?.length === 1 && !checkout.shipToSiteId) {
			shopDispatch({
				type: "select shipping site",
				payload: {
					siteId: sites[0].id,
				},
			})
		}
	}, [checkout.shipToSiteId, sitesLoading, shopDispatch, sites])

	const selectedAddress: GenericAddress | null = useMemo(() => {
		if (checkout.alternateAddress) {
			return checkout.alternateAddress
		} else if (checkout.shipToSiteId) {
			const site = (sites ?? []).find((s) => s.id === checkout.shipToSiteId)

			if (!site && !sitesLoading) {
				console.warn(
					"The site ID in checkout state does not match any of the sites coming back this customer."
				)
			}

			if (site) {
				return getGenericAddress(site)
			}
		}

		return null
	}, [checkout.shipToSiteId, checkout.alternateAddress, sites, sitesLoading])

	return (
		<>
			{selectedAddress ?
				<Box display="flex" alignItems="flex-start">
					<div>
						<ParagraphList lines={getAddressFields(selectedAddress)} />
					</div>

					<Button
						icon="pencil"
						containerProps={{ ml: 2 }}
						onClick={() => setShowSelectModal(true)}
					>
						Change
					</Button>
				</Box>
			:	<>
					<Paragraph color="secondary">No address selected yet</Paragraph>
					<Button
						onClick={() => setShowSelectModal(true)}
						variant="secondary-cta"
						containerProps={{ my: 2 }}
					>
						Select shipping address
					</Button>
				</>
			}

			{/* If you have sites but have entered an alternate address, show the ship to dropdown. */}
			<AnimatedEntrance show={!!sites?.length && !!checkout.alternateAddress}>
				<Paragraph mt={2} mb={0.25}>
					This order is for:
				</Paragraph>
				<SiteSelector
					value={checkout.shipToSiteId}
					onChange={(siteId) => {
						shopDispatch({
							type: "select shipping site",
							payload: {
								siteId,
								preserveAlternateAddress: true,
							},
						})
					}}
					label={""}
				/>
			</AnimatedEntrance>

			<AnimatedEntrance show={!isDb && !!selectedAddress} d="flex" mt={2} gap={1}>
				<PhoneInput
					value={checkout.phone}
					onChange={(value) => {
						shopDispatch({
							type: "set checkout phone",
							payload: value,
						})
					}}
					fillContainer={false}
					label="Delivery contact phone (required)"
					width={18}
				/>
			</AnimatedEntrance>

			<SelectAddressModal
				isOpen={showSelectModal}
				onClose={() => setShowSelectModal(false)}
				disableAlternateAddress={!!punchOutSessionId}
			/>
		</>
	)
}
