export enum LineItemType {
	FirstHour = "1",
	FlatRateTravelCharge = "2",
	TravelLabor = "3",
	NonStandardFirstHour = "4",
	NonStandardFlatRate = "5",
	Freight = "6",
	FuelSurcharge = "7",
	AdditionalHours = "8",
	LaborHours = "9",
	LaborRate = "10",
	AdditionalHoursOvertime = "11",
	LaborOvertime = "12",
	LaborOvertimeRate = "13",
	MiscellaneousSuppliesCharge = "14",
	MMInspection = "15",
	PMInspection = "16",
	NonContractFlatRateCharge = "17",
	NonContractLaborRate = "18",
	OutsidePurchase = "19",
	Parts = "21",
	SubContractCharge = "22",
	InspectionOnly = "20",
	Salt = "23",
	OilChange = "24",
	AppointmentNotification = "25",
	TankEndorsement = "26",
	StopCharge = "27",
	Redelivery = "28",
	ProtectiveService = "29",
	PrivateResidence = "30",
	PalletJack = "31",
	LimitedAccess = "32",
	JobSiteDelivery = "33",
	InsideDeliveryPickup = "34",
	HighCostRegion = "35",
	HandlingFee = "36",
	HazmatFee = "37",
	GuaranteedDelivery = "38",
	LiftGate = "39",
	TireDressingPromotion = "40",
	TowlA = "640138538385409",
	TowlB = "640138538385410",
	TowlC = "640138538385411",
	Installation = "640138538385412",
	BayPrep = "640138538385413",
}
